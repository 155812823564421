import {call, put, takeLatest} from 'redux-saga/effects';
import {
    ADMIN_REVENUE_REQUEST,
    ADMIN_REVENUE_SUCCESS,
    ADMIN_REVENUE_HISTORY_REQUEST,
    ADMIN_REVENUE_HISTORY_SUCCESS
} from '../../actionTypes/revenue';

import { doAdminRevenue, doAdminRevenueHistory  } from '../../../com/revenue';
import { getUser } from '../../../com/user'
function*  adminRevenueFetch(params) {
    const json = yield call(doAdminRevenue, params.payload);   
    yield put({ type: ADMIN_REVENUE_SUCCESS, payload: json.data});
}

function*  adminRevenueHistoryFetch(params) {
    const json = yield call(doAdminRevenueHistory, params.payload);   
    const { history } = json.data
    for (let index = 0; index < history.length; index++) {
        const consumerResponse =  yield call(getUser, {
            remote_user_id: json.data && history[index].consumer_id
        });
        history[index].consumer = consumerResponse.data.user; 
    }
    yield put({ type: ADMIN_REVENUE_HISTORY_SUCCESS, payload: json.data});
}

export function* adminRevenueSaga() {
    yield takeLatest(ADMIN_REVENUE_REQUEST, adminRevenueFetch)
}

export function* adminRevenueHistorySaga() {
    yield takeLatest(ADMIN_REVENUE_HISTORY_REQUEST, adminRevenueHistoryFetch)
}