export const INSERT_SCHEDULE_REQUEST = 'INSERT_SCHEDULE_REQUEST';
export const UPDATE_SCHEDULE_REQUEST = 'UPDATE_SCHEDULE_REQUEST';
export const GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST';
export const LIST_SCHEDULE_REQUEST = 'LIST_SCHEDULE_REQUEST';
export const PLANS_SCHEDULE_REQUEST = 'PLANS_SCHEDULE_REQUEST';
export const REMOVE_SCHEDULE_REQUEST = 'REMOVE_SCHEDULE_REQUEST';
export const INIT_SCHEDULE_REQUEST = "INIT_SCHEDULE_REQUEST";

export const INSERT_SCHEDULE_SUCCESS = 'INSERT_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const LIST_SCHEDULE_SUCCESS = 'LIST_SCHEDULE_SUCCESS';
export const PLANS_SCHEDULE_SUCCESS = 'PLANS_SCHEDULE_SUCCESS';
export const REMOVE_SCHEDULE_SUCCESS = 'REMOVE_SCHEDULE_SUCCESS';
