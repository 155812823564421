import {  
    INSERT_PLANS_REQUEST,
    INSERT_PLANS_SUCCESS,
    SEARCH_PLANS_REQUEST,
    SEARCH_PLANS_SUCCESS,
    UPDATE_PLANS_REQUEST,
    UPDATE_PLANS_SUCCESS,
    GET_PLANS_REQUEST,
    GET_PLANS_SUCCESS,
    BRANCH_PLANS_REQUEST,
    BRANCH_PLANS_SUCCESS,
    LIST_PLANS_REQUEST,
    LIST_PLANS_SUCCESS,
    REMOVE_PLANS_REQUEST,
    REMOVE_PLANS_SUCCESS,
    INIT_PLANS_REQUEST
  } from '../../actionTypes/plans';
  
  const plansReducer = (state = {}, action) => {
    switch (action.type) {  
      case INIT_PLANS_REQUEST:
        state = { ...state, insert: {}, update: {}, get: [], branch: {} , list: {}, remove: {}, search: {} };
        break;
      case INSERT_PLANS_REQUEST:
        state = { ...state, insert: {} };
        break;
      case SEARCH_PLANS_REQUEST:
          state = { ...state, insert: {} };
          break;
        case UPDATE_PLANS_REQUEST:
          state = { ...state, insert: {} };
          break;
      case GET_PLANS_REQUEST:
          state = { ...state, get: [] };
          break;
      case BRANCH_PLANS_REQUEST:
        state = { ...state, branch: {} };
        break;
      case LIST_PLANS_REQUEST:
          state = { ...state, list: {} };
          break;
      case REMOVE_PLANS_REQUEST:
        state = { ...state, remove: {} };
        break;
    case INSERT_PLANS_SUCCESS:
        state = { ...state,
                  insert:{
                      ...action.payload  
                  }
              };
        break;
    case SEARCH_PLANS_SUCCESS:
      state = { ...state,
                search:{
                    ...action.payload  
                }
            };
      break;
        case UPDATE_PLANS_SUCCESS:
          state = { ...state,
                    update:{
                        ...action.payload  
                    }
                };
          break;  
        case GET_PLANS_SUCCESS:
        state = { ...state,
                  get:{
                      ...action.payload  
                  }
              };
        break;
      case LIST_PLANS_SUCCESS:
        state = { ...state,
                  list:{
                      ...action.payload
                  }
              };
      break;        
      case BRANCH_PLANS_SUCCESS:
        state = { ...state,
                  branch:{
                      ...action.payload
                  }
                };        
        break;
      case REMOVE_PLANS_SUCCESS:
        state = { ...state,
                  remove:{
                      ...action.payload
                  }
                };        
        break;
      default:
        break;
    }
    return state;
  };
  export default plansReducer;