import logoPng from '../../../asset/images/logo.png';
import { Nav, Navbar, Form, FormControl, Button, Container } from 'react-bootstrap';
import { FiSunrise, FiHelpCircle, FiDivideCircle, FiHome } from 'react-icons/fi'
const Header = (props) => {
    return (<>
    <Navbar bg="dark" expand="lg">
  <Container fluid>
    <Navbar.Brand href="/">
        <img src={logoPng} width={'10%'} />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="scroll-ichase">
      <Nav className="ms-auto mr-auto" navbarScroll>
      <Nav.Item>
          <Nav.Link href="/" style={{color: 'white'}}><FiHome size={20} className={'m-2'}/>Home</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="/help" style={{color: 'white'}}><FiHelpCircle size={20} className={'m-2'}/>Help</Nav.Link>
        </Nav.Item>
        <Nav.Item >
          <Nav.Link href="/menu" style={{color: 'white'}}><FiDivideCircle size={20} className={'m-2'} />{props.login.name}</Nav.Link>
        </Nav.Item>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
    </>)
}

export default Header;