import React, {useState} from 'react';
import './index.scss';
import logoPng from '../../asset/images/logo.png';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import ActivateUser from '../../components/ActivateUser';
import Confirmation from '../../components/Confirmation';
const ActivateUserLayout = (props) => {
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(false);
    return (
    <Container fluid className={'ew-activate-user-layout'}>
        <Row className={'align-items-center vh-100'}>
            <Col md={7} className={'bg-dark-img vh-100'}>
                <Row className={'justify-content-start align-items-center vh-100'}>
                    <Col md={3} className={'m-5 p-5'}>
                        {show === false && <Spinner color={'#101010'} />}
                        <Image src={logoPng} onLoad={() => setShow(true)} className={'img-fluid'} />
                        <center><div className={'text-white p-3'}><h4>iChase</h4></div></center>
                    </Col>
                </Row>       
            </Col>
            <Col md={5}>
                { success === false && <Row className={'justify-content-start'}>
                    <Col md={6}>
                        <ActivateUser setSuccess={setSuccess} />
                    </Col>   
                </Row> }
                { success === true && <Row className={'justify-content-center'}>
                    <Col md={12}>
                        <Confirmation type={'activateUser'} title={'Successful Member'} />
                    </Col>   
                </Row> }
            </Col>
        </Row>
    </Container>
    );
}

export default ActivateUserLayout;