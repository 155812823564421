import React, {useState, useEffect} from 'react';
import {Row, InputGroup, Form, Button, Card, Table, Image} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { listCashfreeRequest } from '../../action';

const ListCashfree = (props) => {

    useEffect(() => {
        const cashfree = new window.Cashfree(props.payment_session_id);
        cashfree.drop(document.getElementById('cash-free-holder'),dropinConfig);
    }, [props]);

    const dropinConfig = {
        components: [
            "order-details",
            "card",
            "netbanking",
            "app",
            "upi",
        ],
        onSuccess: function(data) {
            if (data.order && data.order.status == "PAID") {
                props.updatePurchaseRequest({
                    is_active: true,
                    order_id: data.order.orderId,
                    purchase_id: props.purchase_id
                });
          } else {
              //order is still active and payment has failed
          }
        },
        onFailure: function(data) {
            props.setPage(4);
        },
        style: {
              //to be replaced by the desired values
              backgroundColor: "#ffffff",
              color: "#11385b", 
              fontFamily: "Lato",
              fontSize: "14px",
              errorColor: "#ff0000",
              theme: "light"
        }
    }

    return (
        <React.Fragment>
        
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      CashfreeList: state.cashfree.list || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        listCashfreeRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ListCashfree);