import Routers from '../../routers/main';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const Content = (props) => {
    return (<Routers login={props.login} />)
}

const mapStateToProps = (state) => {
    return {
      logout: state.logout,
      login: state.login
    }
  };
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(Content);
