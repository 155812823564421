import { 
    INSERT_BRANCH_REQUEST,
    UPDATE_BRANCH_REQUEST,
    GET_BRANCH_REQUEST,
    LIST_BRANCH_REQUEST,
    COMPANIES_BRANCH_REQUEST,
    REMOVE_BRANCH_REQUEST,
    SEARCH_BRANCH_REQUEST,
    INIT_BRANCH_REQUEST
} from '../../actionTypes/branch';

export const searchBranchRequest = (params) => {
  return {
    type: SEARCH_BRANCH_REQUEST,
    payload:params
  };
};

export const initBranchRequest = (params) => {
  return {
    type: INIT_BRANCH_REQUEST,
    payload:params
  };
};

export const insertBranchRequest = (params) => {
  return {
    type: INSERT_BRANCH_REQUEST,
    payload:params
  };
};

export const updateBranchRequest = (params) => {
  return {
    type: UPDATE_BRANCH_REQUEST,
    payload:params
  };
};

export const getBranchRequest = (params) => {
  return {
    type: GET_BRANCH_REQUEST,
    payload:params
  };
};

export const listBranchRequest = (params) => {
  return {
    type: LIST_BRANCH_REQUEST,
    payload:params
  };
};

export const companiesBranchRequest = (params) => {
  return {
    type: COMPANIES_BRANCH_REQUEST,
    payload:params
  };
};

export const removeBranchRequest = (params) => {
  return {
    type: REMOVE_BRANCH_REQUEST,
    payload:params
  };
};
