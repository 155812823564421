import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PageHeader from '../PageHeader';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
const Confirmation = ({type, title, setIndex}) => {
    return (
        <React.Fragment>
            <Row className={'justify-content-center mt-2 pt-2 mb-2 pt-2 align-items-center'} style={{height: '40vh'}}>    
                <Col md={6}>
            <PageHeader title={title}/>
            {type === 'infoActivateUser' && 
                <React.Fragment> 
                <span> Please check your mail. You must receive an OTP from us.</span>   
            </React.Fragment>
            }
            {type === 'activateUser' && 
                <React.Fragment> 
                    <h6> Account Activated Successfully. Please login</h6>
                    <Col md={3}><Link  to={'/login'} className={'text-decoration-none btn btn-primary'}><small>Login</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'Companies' && 
                <React.Fragment> 
                    <h6> Companies Registered Successfully.</h6>
                    <Col md={3}><Link  to={'/menu/companies'} className={'text-decoration-none btn btn-primary'}><small>Companies</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'Branch' && 
                <React.Fragment> 
                    <h6> Branch Registered Successfully.</h6>
                    <Col md={3}><Link  to={'/menu/branch'} className={'text-decoration-none btn btn-primary'}><small>Branch</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'Plans' && 
                <React.Fragment> 
                    <h6> Plans Registered Successfully.</h6>
                    <Col md={3}><Link  to={'/menu/branch'} className={'text-decoration-none btn btn-primary'}><small>Plans</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'VPlans' && 
                <React.Fragment> 
                    <h6> You have successfully Completed the Payment. Please continue the Service</h6>
                    <Col md={3}><Link  to={'/'} className={'text-decoration-none btn btn-primary'}><small>Home</small></Link></Col>   
                </React.Fragment>
            }

            {type === 'Schedule' && 
                <React.Fragment> 
                    <h6> Schedule Registered Successfully.</h6>
                    <Col md={6}><Link  to={'/menu/schedule'} className={'text-decoration-none btn btn-primary'}><small>Schedule</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'Partners' && 
                <React.Fragment> 
                    <h6> Partners Registered Successfully.</h6>
                    <Col md={3}><Link  to={'/menu/partners'} className={'text-decoration-none btn btn-primary'}><small>Partners</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'ListCategories' && 
                <React.Fragment> 
                    <h6> You do not have registered any Categories with us. Please create One.  </h6>
                    <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Register Categories</small></Button>   
                </React.Fragment>
            }
            {type === 'LCompanies' && 
                <React.Fragment> 
                    <h6> You do not have registered any Companies with us. Please create One.  </h6>
                    <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Register Company</small></Button>   
                </React.Fragment>
            }
            {type === 'LTransactions' && 
                <React.Fragment> 
                    <h6> You do not have any transactions with us. Please create One.  </h6>   
                </React.Fragment>
            }
            {type === 'LBranch' && 
                <React.Fragment> 
                    <h6> You do not have registered any Branch with us. Please create One.  </h6>
                    <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Register Branch</small></Button>   
                </React.Fragment>
            }
            {type === 'LEarnings' && 
                <React.Fragment> 
                    <h6> You do not have any earnings. Please create One.  </h6>   
                </React.Fragment>
            }
            {type === 'LRevenue' && 
                <React.Fragment> 
                    <h6> You do not have any revenue. Please create One.  </h6>   
                </React.Fragment>
            }
            {type === 'LTransaction' && 
                <React.Fragment> 
                    <h6> You do not have any Transaction. Please create One.  </h6>   
                </React.Fragment>
            }
            {type === 'SBranch' && 
                <React.Fragment> 
                    <h6> Sorry! We did not find anything suitable match. Please try different search </h6>   
                </React.Fragment>
            }
            {type === 'LPlans' && 
                <React.Fragment> 
                    <h6> You do not have registered any Plans with us. Please create One.  </h6>
                    <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Register Plans</small></Button>   
                </React.Fragment>
            }
            {type === 'LSchedule' && 
                <React.Fragment> 
                    <h6> You do not have registered any Schedule with us. Please create One.  </h6>
                    <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Register Schedule</small></Button>   
                </React.Fragment>
            }
            {type === 'LPartners' && 
                <React.Fragment> 
                    <h6> You do not have registered any Partners with us. Please create One.  </h6>
                    <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Register Partners</small></Button>   
                </React.Fragment>
            }
            {type === 'LInvestment' && 
                <React.Fragment> 
                    <h6> You do not have registered any Investment Defination with us. Please create One.  </h6>
                    <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Register Investment</small></Button>   
                </React.Fragment>
            }
            {type === 'Profile' && 
                <React.Fragment> 
                    <h6> Profile Completed Successfully. Please Continue...  </h6>
                    <Col md={3}><Link  to={'/menu/dashboard'} className={'text-decoration-none btn btn-primary btn btn-primary'}><small>Dashboard</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'Proposal' && 
                <React.Fragment> 
                    <h6> Proposal Created Successfully. It will be available in the Exchange.</h6>
                    <Col md={3}><Link  to={'/menu/proposal'} className={'text-decoration-none btn btn-primary'}><small>Proposal</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'LProposal' && 
                <React.Fragment> 
                    <h6> You do not have registered any Proposal with us. Please create One.  </h6>
                    <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Create Proposal</small></Button>   
                </React.Fragment>
            }
            {type === 'EProposal' && 
                <React.Fragment> 
                    <h6> You do not have registered any Companies with us. Please create One.  </h6>
                    <Col md={3}><Link  to={'/login'} className={'text-decoration-none btn btn-primary'}><small>Companies</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'Purchase' && 
                <React.Fragment> 
                    <h6> You had Purchased the Credits Successfully. Continue Browsing.</h6>
                    <Col md={3}><Link  to={'/menu/purchase'} className={'btn btn-primary text-decoration-none btn btn-primary'}><small>Purchase</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'APurchase' && 
                <React.Fragment> 
                    <h6> Purchase Failed. Please try after Sometime.</h6>
                    <Col md={3}><Link  to={'/menu/purchase'} className={'text-decoration-none btn btn-primary'}><small>Purchase</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'LPurchase' && 
                <React.Fragment> 
                    <h6> You do not have any Purchase History. Please purchase.  </h6>
                    <Col md={4}>
                        <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Purchase Credits</small></Button>
                    </Col>
                </React.Fragment>
            }
            {type === 'SSPurchase' && 
                <React.Fragment> 
                    <h6> You Have Successfully Purchased the Credits </h6>
                    <Col md={3}><Link  to={'/menu/purchase'} className={'text-decoration-none btn btn-primary'}><small>purchase</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'Redeem' && 
                <React.Fragment> 
                    <h6> You had Redeemed the Credits Successfully. Continue Buying the...</h6>
                    <Col md={3}><Link  to={'/menu/proposal'} className={'text-decoration-none btn btn-primary'}><small>Redeem</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'SSRedeem' && 
                <React.Fragment> 
                    <h6> You Have Successfully Redeemed the Credits </h6>
                    <Col md={3}><Link  to={'/menu/redeem'} className={'text-decoration-none btn btn-primary'}><small>redeem</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'LRedeem' && 
                <React.Fragment> 
                    <h6> You do not have any Redeem History. Please redeem.  </h6>
                    <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Redeem Credits</small></Button>   
                </React.Fragment>
            }
            {type === 'FPurchase' && 
                <React.Fragment> 
                    <h6> Please upload company documents </h6>
                    <Col md={3}><Link  to={'/menu/file'} className={'text-decoration-none btn btn-primary'}><small>Documents Upload</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'PPurchase' && 
                <React.Fragment> 
                    <h6> We do not have your profile Completed. Please Complete and Redeem the Credits </h6>
                    <Col md={3}><Link  to={'/menu/profile'} className={'text-decoration-none btn btn-primary'}><small>profile</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'LWallet' && 
                <React.Fragment> 
                    <h6> You do not have any balance in the wallet.</h6> 
                    <Col md={3}><Link  to={'/menu/purchase'} className={'text-decoration-none btn btn-primary'}><small>purchase</small></Link></Col>
                </React.Fragment>
            }
            {type === 'Bank' && 
                <React.Fragment> 
                    <h6> Bank Created Successfully.</h6>
                    <Col md={3}><Link  to={'/menu/bank'} className={'text-decoration-none btn btn-primary'}><small>Bank</small></Link></Col>   
                </React.Fragment>
            }
            {type === 'LBank' && 
                <React.Fragment> 
                    <h6> You do not have registered any Bank with us. Please create One.  </h6>
                    <Button className={'btn btn-primary'} onClick={() => setIndex(1)}><small>Add Bank</small></Button>   
                </React.Fragment>
            }
                            </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {}
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);