import https from '../../axios';

export const doInsertSchedule = async (params) => {
    const {
        created_by,currency,plans_id,type,price } = params;
    try {
        return await https.post('/schedule/insert', {
            created_by,currency,plans_id,type,price
        });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doGetSchedule = async (params) => {
    const { schedule_id } = params;
    try {
        return await https.post('/schedule/get', { schedule_id});
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doListSchedule = async (params) => {
    try {
        return await https.post('/schedule/list', params);
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doPlansSchedule = async (params) => {
    const { plans_id } = params;
    try {
        return await https.post('/schedule/plans', {plans_id});
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doRemoveSchedule = async (params) => {
    const { schedule_id, is_deleted } = params;
    try {
        return await https.post('/schedule/remove', { schedule_id, is_deleted });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doUpdateSchedule = async (params) => {
    const {
        created_by,currency,plans_id,type,price,schedule_id, charge_fee
    } = params;
    try {
        return await https.post('/schedule/update', {
            created_by,currency,plans_id,type,price,schedule_id, charge_fee
        });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}