import React, {useState, useEffect, Suspense} from 'react';
import moment from 'moment';
import {Helmet} from 'react-helmet';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {FiTrash} from 'react-icons/fi';
import Confirmation from '../../Confirmation';
import { userTransactionRequest } from '../../../db/action/transaction';
import PageHeader from '../../PageHeader';
import Error from '../../Error';

const LTransaction = (props) => {
    const {TransactionList, setIndex, setTransactionId, login} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    useEffect(() => {
        setLoading(true);
        props.userTransactionRequest({
            created_by: login.user_id
        });
    }, []);

    useEffect(() => {
        const {success, code, message} = TransactionList;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [TransactionList]);

    if (TransactionList.transactions && TransactionList.transactions.length === 0) {
        return (<Confirmation title={'My Transactions'} type={'LTransactions'} setIndex={setIndex} />);
    }

    return (
        <React.Fragment>
        <PageHeader title={'My Transactions'} show={loading} className={'mb-4'}/>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <Card>
            <Card.Body>
        <Row>
            <Table responsive="md">
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Order Date</th>
                    </tr>
                </thead>
                <tbody>
                {TransactionList.transactions && TransactionList.transactions.map((transaction, key) => {
                    return (
                        <tr key={key}>
                            <td><Image src={'https://via.placeholder.com/20'} roundedCircle/></td>
                            <td>{transaction.plans.name}</td>
                            <td>{transaction.schedule.type}</td>
                            <td>{transaction.schedule.price}</td>
                            <td>{transaction.created_date}</td>
                        </tr>
                    )
                    })}    
                    </tbody>
            </Table>
        </Row>
        </Card.Body>
        </Card>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      TransactionList: state.transaction.user || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        userTransactionRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(LTransaction);