export const INSERT_WALLET_REQUEST = 'INSERT_WALLET_REQUEST';
export const INSERT_WALLET_HISTORY_REQUEST = 'INSERT_WALLET_HISTORY_REQUEST';
export const GET_WALLET_REQUEST = 'GET_WALLET_REQUEST';
export const USER_WALLET_REQUEST = 'USER_WALLET_REQUEST';
export const USER_WALLET_HISTORY_REQUEST = 'USER_WALLET_HISTORY_REQUEST';
export const UPDATE_WALLET_REQUEST = 'UPDATE_WALLET_REQUEST';

export const INSERT_WALLET_SUCCESS = 'INSERT_WALLET_SUCCESS';
export const INSERT_WALLET_HISTORY_SUCCESS = 'INSERT_WALLET_HISTORY_SUCCESS';
export const GET_WALLET_SUCCESS = 'GET_WALLET_SUCCESS';
export const USER_WALLET_SUCCESS = 'USER_WALLET_SUCCESS';
export const USER_WALLET_HISTORY_SUCCESS = 'USERT_WALLET_HISTORY_SUCCESS';
export const UPDATE_WALLET_SUCCESS = 'UPDATE_WALLET_SUCCESS';
