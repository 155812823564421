export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST"
export const INIT_FILE_REQUEST = "INIT_FILE_REQUEST"
export const GET_FILE_REQUEST = "GET_FILE_REQUEST" 
export const REMOVE_FILE_REQUEST = "REMOVE_FILE_REQUEST"  
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS" 
export const REMOVE_FILE_SUCCESS = "REMOVE_FILE_SUCCESS"
export const INSERT_FILE_CONNECT_REQUEST = "INSERT_FILE_CONNECT_REQUEST"
export const INSERT_FILE_CONNECT_SUCCESS = "INSERT_FILE_CONNECT_SUCCESS"
export const GET_FILE_CONNECT_REQUEST = "GET_FILE_CONNECT_REQUEST"
export const GET_FILE_CONNECT_SUCCESS = "GET_FILE_CONNECT_SUCCESS"
export const USER_FILE_CONNECT_REQUEST = "USER_FILE_CONNECT_REQUEST"
export const USER_FILE_CONNECT_SUCCESS = "USER_FILE_CONNECT_SUCCESS"
export const REMOVE_FILE_CONNECT_REQUEST = "REMOVE_FILE_CONNECT_REQUEST"
export const REMOVE_FILE_CONNECT_SUCCESS = "REMOVE_FILE_CONNECT_SUCCESS"