import { 
  ADMIN_REVENUE_REQUEST,
  ADMIN_REVENUE_HISTORY_REQUEST,
} from '../../actionTypes/revenue';

export const adminRevenueRequest = (params) => {
  return {
    type: ADMIN_REVENUE_REQUEST,
    payload:params
  };
};

export const adminRevenueHistoryRequest = (params) => {
  return {
    type: ADMIN_REVENUE_HISTORY_REQUEST,
    payload:params
  };
};