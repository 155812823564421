import React, { useEffect, useState } from 'react';
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import { logoutRequest } from '../../db/action/logout';
import { initLoginRequest } from '../../db/action/login';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import {RiDashboardLine, RiAttachmentLine} from 'react-icons/ri';
import {GiPlagueDoctorProfile, GiBuyCard, GiAbstract034} from 'react-icons/gi';
import {BiBarChartAlt2, BiBuildingHouse, BiShieldQuarter} from 'react-icons/bi';
import {AiOutlineUpload} from 'react-icons/ai';
import {SiWebmoney, SiHandshake } from 'react-icons/si';
import {CgLogOff} from 'react-icons/cg';
import {FaSellsy} from 'react-icons/fa';
import {TbAd, TbAddressBookOff, TbWallet} from 'react-icons/tb';
import {AiOutlineBank} from 'react-icons/ai';
const MainMenu = (props) => {
  const navigate = useNavigate();
  const [isRtl, setIsRtl] = useState(false);
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [collapseOnSelect, setCollapseOnSelect] = useState(false);
  const [exclusiveExpand, setExclusiveExpand] = useState(false);

  const themeName = isDarkTheme ? 'dark' : 'light';

  const onSelect = (eventKey) => {
    if (eventKey)
      document.getElementById(`${eventKey}`)?.scrollIntoView({ behavior: 'smooth' })
  }

  const clearAll = () => {
    props.initLoginRequest();
    props.logoutRequest();
  }
  

  return (
    <>
    <SidebarMenu
      defaultExpanded={false}
      exclusiveExpand={exclusiveExpand}
      collapseOnSelect={collapseOnSelect}
      onSelect={onSelect}
      variant={themeName}
      bg={themeName}
      expand="lg"
      hide="md"
      className={'main-menu'}
    >
      <SidebarMenu.Collapse>
        <SidebarMenu.Header>
          <SidebarMenu.Brand title="React-Bootstrap" href="https://github.com/react-bootstrap/react-bootstrap">
            <span className="react-bootstrap-img" />
          </SidebarMenu.Brand>
          <SidebarMenu.Toggle />
        </SidebarMenu.Header>
        <SidebarMenu.Body>
          <SidebarMenu.Nav>
                <SidebarMenu.Nav.Link onClick={() => navigate('dashboard')}>
                    <SidebarMenu.Nav.Icon><RiDashboardLine /></SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>Home</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                { props.login.is_admin === true && <>
                <SidebarMenu.Nav.Link onClick={() => navigate('categories')}>
                  <SidebarMenu.Nav.Icon><BiBuildingHouse/></SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Categories</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link></>
                }
                { props.login.is_customer === true && <>
                <SidebarMenu.Nav.Link onClick={() => navigate('purchase')}>
                  <SidebarMenu.Nav.Icon><GiBuyCard/></SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Purchase</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link onClick={() => navigate('orders')}>
                  <SidebarMenu.Nav.Icon><TbAd /></SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Orders</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link onClick={() => navigate('wallet')}>
                  <SidebarMenu.Nav.Icon><TbWallet/></SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Wallet</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link></> 
                }
                { props.login.is_partner === true && <>
                <SidebarMenu.Nav.Link onClick={() => navigate('companies')}>
                  <SidebarMenu.Nav.Icon><BiBuildingHouse/></SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Companies</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link onClick={() => navigate('branch')}>
                  <SidebarMenu.Nav.Icon><BiBuildingHouse/></SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Branch</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link onClick={() => navigate('plans')}>
                  <SidebarMenu.Nav.Icon><BiBarChartAlt2 /></SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Plans</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link onClick={() => navigate('schedule')}>
                  <SidebarMenu.Nav.Icon><BiShieldQuarter /></SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Schedule</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link onClick={() => navigate('earnings')}>
                  <SidebarMenu.Nav.Icon><TbWallet/></SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Earnings</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
                <SidebarMenu.Nav.Link onClick={() => navigate('charge')}>
                  <SidebarMenu.Nav.Icon><TbAddressBookOff/></SidebarMenu.Nav.Icon>
                  <SidebarMenu.Nav.Title>Charge</SidebarMenu.Nav.Title>
                </SidebarMenu.Nav.Link>
        </> }
          </SidebarMenu.Nav>
        </SidebarMenu.Body>
        <SidebarMenu.Body>
        

        <SidebarMenu.Nav>
        <SidebarMenu.Nav.Link onClick={() => clearAll()}>
              <SidebarMenu.Nav.Icon><CgLogOff/></SidebarMenu.Nav.Icon>
              <SidebarMenu.Nav.Title>Log Off</SidebarMenu.Nav.Title>
            </SidebarMenu.Nav.Link>
        </SidebarMenu.Nav>
        </SidebarMenu.Body>
      </SidebarMenu.Collapse>
    </SidebarMenu>
    </>);
}

const mapStateToProps = (state) => {
  return {
    logout: state.logout,
    login: state.login
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
      logoutRequest,
      initLoginRequest
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);