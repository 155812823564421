import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead';
import PhoneCodeJson from '../../../data/PhoneCode/index.json';
import { insertPlansRequest, getPlansRequest, updatePlansRequest, initPlansRequest } from '../../../db/action/plans';
import { listCategoriesRequest } from '../../../atom/categories/action';
import File from '../../File';
import SBranch from '../../Branch/Search';
import Confirmation from '../../Confirmation';
import { HiArchive, HiArrowCircleRight, HiArrowCircleUp, HiOutlineMail } from 'react-icons/hi';
import { CgRename, CgShortcut, CgAdidas, CgAlignMiddle, CgArrowLeftR, CgAirplane, CgArrowLongRightR, CgArrowBottomRight, CgCalendar, CgClapperBoard } from 'react-icons/cg';
import { TbAddressBook, TbAbacus } from 'react-icons/tb';
import DatePicker from 'react-datepicker';
import { FcPhone, FcGlobe, FcLink } from 'react-icons/fc';
import {TbGenderEpicene} from 'react-icons/tb';
import {isUndefined, isEmpty } from 'lodash';
import isNumeric from 'validator/es/lib/isNumeric';
import isEmail from 'validator/es/lib/isEmail';
import PageHeader from '../../PageHeader';
import Error from '../../Error';

const EPlans = (props) => {
    const {login, PlansCreate, PlansUpdate, PlansGet, plans_id } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [connect_plans_id, setConnectPlansId] = useState();
    const navigate = useNavigate();
    const [branch, transferBranch] = useState({});
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    const [plans, setPlans] = useState({ 
        name:'',
        code:'',
        categories_id:'',
        categories_name:'',
        summary:'',
        branch_id:'',
        start_date:'',
        end_date:'',
        type:'',
        url:'',
        video_url:'',
        phone:'',
        email:'',
        country:'',
        line1:'',
        line2:'',
        city:'',
        state:'',
        location:'',
        country: [],
        phoneCode: [],
        created_by: login.user_id
    });
    
    useEffect(() => {
        props.listCategoriesRequest({
            is_active: true
        });
        if (isEmpty(plans_id) === false) {
            setLoading(true);
            props.getPlansRequest({
                plans_id
            });
        }
    }, []);

    const handlePhoneCode = (valueMixed) => {
        const object = {...plans};
        object.phoneCode = valueMixed.length ? [valueMixed[0].dial_code]: [];
        setPlans(object); 
    };

    const handleType = (valueMixed) => {
        const object = {...plans};
        object.type = valueMixed.length ? [valueMixed[0].name]: [];
        setPlans(object); 
    };

    const handleCategories = (valueMixed) => {
        const object = {...plans};
        object.categories_name = valueMixed.length ? [valueMixed[0].name]: [];
        object.categories_id = valueMixed.length ? valueMixed[0].categories_id: '';
        setPlans(object); 
    };
    const handleChange = (e) => {
        const {name, value} = e.target;
        const object = {...plans};
        object[name] = value;
        setPlans(object);
    };

    const handleCountry = (valueMixed) => {
        const object = {...plans};
        object.country = valueMixed.length ? [valueMixed[0].code]: [];
        setPlans(object); 
    };

    const handlePlansInfo = () => {
        if (isEmpty(branch.branch_id) === true) {
            setInfo({
                ...info,
                  content: 'Please Select Branch',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.name) === true) {
            setInfo({
                ...info,
                  content: 'Please specify Branch Name',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.categories_id) === true) {
            setInfo({
                ...info,
                  content: 'Please Select Category',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.code) === true) {
            setInfo({
                ...info,
                  content: 'Please specify Code of the Plans',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.summary) === true) {
            setInfo({
                ...info,
                  content: 'Please Describe about your plans',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.type) === true) {
            setInfo({
                ...info,
                  content: 'Please Select the plan type',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.line1) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify line1',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.line2) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify line2',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.city) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify city',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.state) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify the state',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.latitude.toString()) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify latitude',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.longitude.toString()) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify longitude',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.type) === true) {
            setInfo({
                ...info,
                  content: 'Please Select the plan type',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.url) === true) {
            setInfo({
                ...info,
                  content: 'Please Give me Plans Website URL',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.video_url) === true) {
            setInfo({
                ...info,
                  content: 'Please give me Branch Video URL',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(plans.country) === true) {
            setInfo({
                ...info,
                  content: 'Please Select the Country',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmail(plans.email) === false) {
            setInfo({
                ...info,
                  content: 'Invalid Email',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
       }
        if (isEmpty(plans.phoneCode) === true) {
            setInfo({
                ...info,
                content: 'Invalid Phone Code. Please Choose from the List',
                variant: 'warning'
            });
            setShowError(true);
            return;  
       }
       if (isNumeric(plans.phone) === false) {
        setInfo({
            ...info,
              content: 'Invalid Phone Number. Please Give only Numbers',
              variant: 'warning'
        });
        setShowError(true);
        return;  
       }

       setLoading(true);
       const data = {...plans};
       data.branch_id = branch.id;
       data.country = data.country[0];
       data.phoneCode = data.phoneCode[0];
       data.type = data.type[0];
       data.location = {
            type: 'Point',
            coordinates: [data.longitude, data.latitude]
        }
        if (isUndefined(plans_id) === false) {
        props.updatePlansRequest(data);
       } else {
        props.insertPlansRequest(data);
       }
       return;
    }
    
    useEffect(() => {
        const {success, code, message, plans} = PlansCreate;
        if(isUndefined(success)) {
            return false;
        }

        if(success === true) { 
            setConnectPlansId(plans.id); 
            setActiveIndex(2);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [PlansCreate]);

    useEffect(() => {
        const {success, code, message, plans} = PlansUpdate;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setConnectPlansId(plans.id);
            setActiveIndex(2); 
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [PlansUpdate]);

    useEffect(() => {
        const { success, message, plans } = PlansGet;
        if(isUndefined(success)) {
            return false;
        }
        if(isUndefined(plans && plans.name) === false) {
            const object = {...plans};
            object.country = [object.country];
            object.phoneCode = [object.phoneCode || ''];
            object.type = [object.type || ''];
            object.start_date = moment(object.start_date).toDate();
            object.end_date = moment(object.end_date).toDate();
            object.latitude = object.location.coordinates[1];
            object.longitude = object.location.coordinates[0];
            object.categories_id = object.categories.categories_id;
            object.categories_name = [object.categories.name];
            setIsDone(false);
            setPlans(object);
            setLoading(false);
        }
    }, [PlansGet]);

    if(isDone === true) {
        return (
            <Confirmation title={'Plans Registered'} type={'Plans'} />
        )
    };

    // search
    if(activeIndex === 0 && !plans_id) {
        return (
            <React.Fragment>
                <SBranch 
                    buttonName={'Next'}
                    branch={plans.branch} 
                    selectedBranchId={plans.branch_id}
                    setActiveIndex={setActiveIndex}
                    transferBranch={transferBranch} 
                    indexNumber={1} 
                />
            </React.Fragment>
        )
    } 

    if(activeIndex === 0 && plans.branch_id && plans.branch) {
        return (
            <React.Fragment>
                <SBranch 
                    buttonName={'Next'} 
                    branch={plans.branch}
                    selectedBranchId={plans.branch_id}
                    setActiveIndex={setActiveIndex}
                    transferBranch={transferBranch} 
                    indexNumber={1} 
                />
            </React.Fragment>
        )
    }
    // basic 1
    if(activeIndex === 1) {
        return (
            <React.Fragment>
            <PageHeader title={`${!!(plans_id) === true ? 'Update Plans' : 'Register Plans'}`} show={loading} className={'mb-4'}/>
            {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
            <Card border={'light'} className={'border-0'}>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><CgRename /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Name'} 
                        defaultValue={plans.name} 
                        name={'name'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><CgClapperBoard /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-categories'
                        style={{width: '70%'}}
                        className={'border-0'}
                        labelKey="name"
                        onChange={(value) => handleCategories(value)}
                        options={props.CategoriesList.categories}
                        placeholder="Categories"
                        selected={plans.categories_name}
                    />
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <Row>
                        <Col sm={1} className={'pt-2'}>
                            <InputGroup>
                            <InputGroup.Text className={'bg-white border-0'}><CgCalendar /></InputGroup.Text> 
                            </InputGroup>
                        </Col>
                        <Col sm={6}>
                        <DatePicker 
                        dateFormat={'yyyy-MM-dd'}
                        placeholderText={'Start Date'}
                        className={'border-0 form-control form-control-lg'}
                        selected={plans.start_date} 
                        value={plans.start_date}
                        onChange={(start_date) => setPlans({...plans, start_date})} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <Row>
                        <Col sm={1} className={'pt-2'}>
                            <InputGroup>
                            <InputGroup.Text className={'bg-white border-0'}><CgArrowBottomRight /></InputGroup.Text> 
                            </InputGroup>
                        </Col>
                        <Col sm={6}>
                        <DatePicker 
                        dateFormat={'yyyy-MM-dd'}
                        placeholderText={'End Date'}
                        className={'border-0 form-control form-control-lg'}
                        selected={plans.end_date} 
                        onChange={(end_date) => setPlans({...plans, end_date})} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><CgAirplane /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-plans-type'
                        style={{width: '50%'}}
                        className={'border-0'}
                        labelKey="name"
                        onChange={(value) => handleType(value)}
                        options={[{name: 'online'},{name:'physical'}]}
                        placeholder="Type of the Plan"
                        selected={plans.type}
                    />
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><CgShortcut /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        maxLength={4}
                        placeholder={'Code'} 
                        defaultValue={plans.code} 
                        name={'code'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><TbGenderEpicene /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        as="textarea" rows={3}
                        className={'border-0'} required
                        placeholder={'Summary'} 
                        defaultValue={plans.summary} 
                        name={'summary'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcLink /></InputGroup.Text>
                    <Form.Control
                        className={'border-0'} required 
                        placeholder={'Plan website URL'} 
                        defaultValue={plans.url} 
                        name={'url'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><TbAddressBook /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Video URL'} 
                        defaultValue={plans.video_url} 
                        name={'video_url'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiOutlineMail/></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Email'} 
                        defaultValue={plans.email} 
                        name={'email'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiArchive /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Line1'} 
                        defaultValue={plans.line1} 
                        name={'line1'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiArrowCircleRight /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Line2'} 
                        defaultValue={plans.line2} 
                        name={'line2'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiArrowCircleRight /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'City'} 
                        defaultValue={plans.city} 
                        name={'city'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiArrowCircleUp /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'State'} 
                        defaultValue={plans.state} 
                        name={'state'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcPhone /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-phone-code'
                        style={{width: '20%'}}
                        className={'border-0'}
                        labelKey="dial_code"
                        onChange={(value) => handlePhoneCode(value)}
                        options={PhoneCodeJson}
                        placeholder="Code"
                        selected={plans.phoneCode}
                    />
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Phone'} 
                        defaultValue={plans.phone} 
                        name={'phone'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcGlobe /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Latitude'} 
                        defaultValue={plans.latitude} 
                        name={'latitude'} 
                        onChange={(event) => handleChange(event)}/>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Longitude'} 
                        defaultValue={plans.longitude} 
                        name={'longitude'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcGlobe /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-country-code'
                        className={'border-0'}
                        labelKey="code"
                        onChange={(value) => handleCountry(value)}
                        options={PhoneCodeJson}
                        placeholder="Country Code"
                        selected={plans.country}
                    /> 
                    </InputGroup>
                </Form.Group>
                 
                <Form.Group className={'mt-2'}>
                    <Row>
                        <Col md={4}>
                        <Button className={'btn btn-primary'}
                            onClick={() => handlePlansInfo()}
                        >Next</Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Card>
            </React.Fragment>
        )
    } 

    // file
    if(activeIndex === 2) {
        return (
            <React.Fragment>
                <File 
                    buttonName={'Complete'} 
                    setActiveIndex={setActiveIndex} 
                    setIsDone={setIsDone} 
                    connect_id={connect_plans_id} />
            </React.Fragment>
        )
    }

    return (<React.Fragment></React.Fragment>)
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      CategoriesList: state.categories.list || {},
      PlansGet: state.plans.get || {},
      PlansCreate: state.plans.insert || {},
      PlansUpdate: state.plans.update || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getPlansRequest,
        listCategoriesRequest,
        updatePlansRequest,
        insertPlansRequest,
        initPlansRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(EPlans);