import React, {useState} from 'react';
import './index.scss';
import showSvg from '../../asset/images/ew-login-layout.svg';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import LWallet from '../../components/Wallet/List';
const WalletLayout = (props) => {
    const [show, setShow] = useState(false);
    return (
    <>    
    <Container fluid className={'ew-wallet'}>
        <Row className={'justify-content-center'}>
            <Col md={8}><LWallet {...props} /></Col>
        </Row>
    </Container>
    </>
    );
}

export default WalletLayout;