import https from '../../axios';

export const doAdminRevenue = async (params) => {
    const { created_by } = params;
    try {
        return await https.post('/wallet/admin', {created_by});
    } catch(error) {
        return error;
    }
}

export const doAdminRevenueHistory = async (params) => {
    const { created_by } = params;
    try {
        return await https.post('/wallet/history/admin', {created_by});
    } catch(error) {
        return error;
    }
}

