import https from '../../../axios';

export const doInsertCategories = async (params) => {
    const {
        name, about, icon, is_active
    } = params;
    try {
        return await https.post('/categories/insert', {
            name, about, icon, is_active
        });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doUpdateCategories = async (params) => {
    const {
        categories_id,name, about, icon, is_active
    } = params;
    try {
        return await https.post('/categories/update', {
            categories_id,name, about, icon,  is_active
        });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doGetCategories = async (params) => {
    const { categories_id } = params;
    try {
        return await https.post('/categories/get', { categories_id});
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doSearchCategories = async (params) => {
    try {
        return await https.post('/categories/search', params);
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doListCategories = async (params) => {
    try {
        return await https.post('/categories/list', params);
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doRemoveCategories = async (params) => {
    const { categories_id } = params;
    try {
        return await https.post('/categories/remove', { categories_id, is_deleted: true });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}