export const INSERT_PURCHASE_REQUEST = 'INSERT_PURCHASE_REQUEST';
export const UPDATE_PURCHASE_REQUEST = 'UPDATE_PURCHASE_REQUEST';
export const GET_PURCHASE_REQUEST = 'GET_PURCHASE_REQUEST';
export const USER_PURCHASE_REQUEST = 'USER_PURCHASE_REQUEST';
export const FEE_PURCHASE_REQUEST = 'FEE_PURCHASE_REQUEST';

export const INSERT_PURCHASE_SUCCESS = 'INSERT_PURCHASE_SUCCESS';
export const GET_PURCHASE_SUCCESS = 'GET_PURCHASE_SUCCESS';
export const USER_PURCHASE_SUCCESS = 'USER_PURCHASE_SUCCESS';
export const FEE_PURCHASE_SUCCESS = 'FEE_PURCHASE_SUCCESS';
export const UPDATE_PURCHASE_SUCCESS = 'UPDATE_PURCHASE_SUCCESS';
