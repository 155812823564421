import {  
    INSERT_SCHEDULE_REQUEST,
    INSERT_SCHEDULE_SUCCESS,
    UPDATE_SCHEDULE_REQUEST,
    UPDATE_SCHEDULE_SUCCESS,
    GET_SCHEDULE_REQUEST,
    GET_SCHEDULE_SUCCESS,
    PLANS_SCHEDULE_REQUEST,
    PLANS_SCHEDULE_SUCCESS,
    LIST_SCHEDULE_REQUEST,
    LIST_SCHEDULE_SUCCESS,
    REMOVE_SCHEDULE_REQUEST,
    REMOVE_SCHEDULE_SUCCESS,
    INIT_SCHEDULE_REQUEST
  } from '../../actionTypes/schedule';
  
  const scheduleReducer = (state = {}, action) => {
    switch (action.type) {  
      case INIT_SCHEDULE_REQUEST:
        state = { ...state, insert: {}, update: {}, get: [], plans: {} , list: {}, remove: {} };
        break;
      case INSERT_SCHEDULE_REQUEST:
        state = { ...state, insert: {} };
        break;
        case UPDATE_SCHEDULE_REQUEST:
          state = { ...state, insert: {} };
          break;
      case GET_SCHEDULE_REQUEST:
          state = { ...state, get: [] };
          break;
      case PLANS_SCHEDULE_REQUEST:
        state = { ...state, plans: {} };
        break;
      case LIST_SCHEDULE_REQUEST:
          state = { ...state, list: {} };
          break;
      case REMOVE_SCHEDULE_REQUEST:
        state = { ...state, remove: {} };
        break;
    case INSERT_SCHEDULE_SUCCESS:
        state = { ...state,
                  insert:{
                      ...action.payload  
                  }
              };
        break;
        case UPDATE_SCHEDULE_SUCCESS:
          state = { ...state,
                    update:{
                        ...action.payload  
                    }
                };
          break;  
        case GET_SCHEDULE_SUCCESS:
        state = { ...state,
                  get:{
                      ...action.payload  
                  }
              };
        break;
      case LIST_SCHEDULE_SUCCESS:
        state = { ...state,
                  list:{
                      ...action.payload
                  }
              };
      break;        
      case PLANS_SCHEDULE_SUCCESS:
        state = { ...state,
                  plans:{
                      ...action.payload
                  }
                };        
        break;
      case REMOVE_SCHEDULE_SUCCESS:
        state = { ...state,
                  remove:{
                      ...action.payload
                  }
                };        
        break;
      default:
        break;
    }
    return state;
  };
  export default scheduleReducer;