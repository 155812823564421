export const INSERT_PLANS_REQUEST = 'INSERT_PLANS_REQUEST';
export const SEARCH_PLANS_REQUEST = 'SEARCH_PLANS_REQUEST';
export const UPDATE_PLANS_REQUEST = 'UPDATE_PLANS_REQUEST';
export const GET_PLANS_REQUEST = 'GET_PLANS_REQUEST';
export const LIST_PLANS_REQUEST = 'LIST_PLANS_REQUEST';
export const BRANCH_PLANS_REQUEST = 'BRANCH_PLANS_REQUEST';
export const REMOVE_PLANS_REQUEST = 'REMOVE_PLANS_REQUEST';
export const INIT_PLANS_REQUEST = "INIT_PLANS_REQUEST";

export const INSERT_PLANS_SUCCESS = 'INSERT_PLANS_SUCCESS';
export const SEARCH_PLANS_SUCCESS = 'SEARCH_PLANS_SUCCESS';
export const UPDATE_PLANS_SUCCESS = 'UPDATE_PLANS_SUCCESS';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const LIST_PLANS_SUCCESS = 'LIST_PLANS_SUCCESS';
export const BRANCH_PLANS_SUCCESS = 'BRANCH_PLANS_SUCCESS';
export const REMOVE_PLANS_SUCCESS = 'REMOVE_PLANS_SUCCESS';
