import https from '../../../axios';

export const doInsertCashfree = async (params) => {
    const {
    } = params;
    try {
        return await https.post('/cashfree/token', params);
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doUpdateCashfree = async (params) => {
    const {
        cashfree_id
    } = params;
    try {
        return await https.post('/cashfree/update', {
            cashfree_id
        });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doGetCashfree = async (params) => {
    const { cashfree_id } = params;
    try {
        return await https.post('/cashfree/get', { cashfree_id});
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doSearchCashfree = async (params) => {
    try {
        return await https.post('/cashfree/search', params);
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doListCashfree = async (params) => {
    try {
        return await https.post('/cashfree/list', {});
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}

export const doRemoveCashfree = async (params) => {
    const { cashfree_id } = params;
    try {
        return await https.post('/cashfree/remove', { cashfree_id, is_deleted: true });
    } catch(exception) {
        const error = exception.toJSON();
        return { data: {'success' : false, 'code': error.code, 'message': error.message } };
    }
}