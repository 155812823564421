import React, {useEffect, useState} from 'react';
import './index.scss';
import showSvg from '../../asset/images/ew-login-layout.svg';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import LCompanies from '../../components/Companies/List';
import ECompanies from '../../components/Companies/Edit';
const CompaniesLayout = (props) => {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [companies_id, setCompaniesId] = useState();
    
    useEffect(() => {
        if (props.isMenu === true) {
            setIndex(0);
        }
    }, [props])

    return (
    <>    
    <Container fluid className={'ew-companies'}>
        <Row className={'justify-content-center pt-3'}>
            {index === 0 && <Col sm={10} md={10} lg={12}><LCompanies {...props} setIndex={setIndex} setCompaniesId={setCompaniesId} /></Col>}
            {index === 1 && <Col sm={6} md={4} lg={4} ><ECompanies {...props} setIndex={setIndex} companies_id={companies_id}/></Col>}
        </Row>
    </Container>
    </>
    );
}

export default CompaniesLayout;