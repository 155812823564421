import React, {useEffect, useState} from 'react';
import './index.scss';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import ECharge from '../../components/Charge/Edit';
const ChargeLayout = (props) => {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [Charge_id, setChargeId] = useState();
    
    useEffect(() => {
        if (props.isMenu === true) {
            setIndex(0);
        }
    }, [props])

    return (
    <>    
    <Container fluid className={'ew-Charge'}>
        <Row className={'justify-content-center pt-4'}>
            {index === 0 && <Col md={10}><ECharge {...props} setIndex={setIndex} /></Col>}
        </Row>
    </Container>
    </>
    );
}

export default ChargeLayout;