export const INSERT_INTEREST_REQUEST = 'INSERT_INTEREST_REQUEST';
export const GET_INTEREST_REQUEST = 'GET_INTEREST_REQUEST';
export const LIST_INTEREST_REQUEST = 'LIST_INTEREST_REQUEST';
export const PARTNERS_INTEREST_REQUEST = 'PARTNERS_INTEREST_REQUEST';
export const REMOVE_INTEREST_REQUEST = 'REMOVE_INTEREST_REQUEST';
export const INIT_INTEREST_REQUEST = "INIT_INTEREST_REQUEST";

export const INSERT_INTEREST_SUCCESS = 'INSERT_INTEREST_SUCCESS';
export const GET_INTEREST_SUCCESS = 'GET_INTEREST_SUCCESS';
export const LIST_INTEREST_SUCCESS = 'LIST_INTEREST_SUCCESS';
export const PARTNERS_INTEREST_SUCCESS = 'PARTNERS_INTEREST_SUCCESS';
export const REMOVE_INTEREST_SUCCESS = 'REMOVE_INTEREST_SUCCESS';
