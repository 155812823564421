import {  
  ADMIN_REVENUE_HISTORY_REQUEST, 
  ADMIN_REVENUE_HISTORY_SUCCESS,
  ADMIN_REVENUE_REQUEST,
  ADMIN_REVENUE_SUCCESS,
} from '../../actionTypes/revenue';

const revenueReducer = (state = {}, action) => {
  switch (action.type) {  
    case ADMIN_REVENUE_REQUEST:
      state = { ...state, admin: {} };
      break;
    case ADMIN_REVENUE_HISTORY_REQUEST:
      state = { ...state, adminHistory: {} };
      break;

    case ADMIN_REVENUE_SUCCESS:
      state = { ...state,
                admin:{
                    ...action.payload
                }
              };        
      break;
      case ADMIN_REVENUE_HISTORY_SUCCESS:
        state = { ...state,
                  adminHistory:{
                      ...action.payload  
                  }
              };
        break;   
    default:
      break;
  }
  return state;
};
export default revenueReducer;