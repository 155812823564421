import logoPng from '../../../asset/images/logo.png';
import { Nav, Navbar, Form, FormControl, Button, Container } from 'react-bootstrap';
import { FiSunrise, FiHelpCircle, FiDivideCircle } from 'react-icons/fi'
import { useNavigate, Link } from 'react-router-dom'

const Header = (props) => {

  const navigate = useNavigate();  
  const handleLogin = () => {
      navigate('login');
  }
  
  return (
    <>
    <Navbar  expand="lg">
  <Container fluid>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="scroll-ichase">
      <Nav className="ms-auto mr-auto" navbarScroll>
        <Nav.Item>
          <Nav.Link href="/help" className={'text-white'}><FiHelpCircle size={20} className={'m-2'}/>Help</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link help="/menu" className={'text-white'}>
              {props.login.name ? 
                <Link to='/menu' className={'text-decoration-none text-white'}> 
                        <FiDivideCircle size={20} className={'m-2'} />
                        {props.login.name} </Link>  : 
                        <a
                        onClick={() => handleLogin()}
                    ><FiDivideCircle size={20} className={'m-2'} /> Login</a>}
            </Nav.Link>
        </Nav.Item>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
    </>)
}

export default Header;