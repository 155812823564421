import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, Button, Card, Badge} from 'react-bootstrap';
import { useNavigate, Link} from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {FiArrowDownRight, FiEdit, FiLink2, FiTrash, FiTv} from 'react-icons/fi';
import Confirmation from '../../Confirmation';
import { searchPlansRequest } from '../../../db/action/plans';
import { listCategoriesRequest } from '../../../atom/categories/action'
import PageHeader from '../../PageHeader';
import Error from '../../Error';
import { FcAddRow, FcFaq, FcMiddleBattery, FcSearch } from 'react-icons/fc';
const venueOptions = [{name: 'online'}, {name: 'physical'}, {name: 'near'} ];
const limit = 12;
const HomePlace = (props) => {
    const {PlansSearch, setIndex, place} = props;
    const navigate = useNavigate();
    const [query, setQuery] = useState({
    });
    const [name, setName] = useState([]);
    const [venue, setVenue] = useState([]);
    const [isSearch, setSearch] = useState(false);
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [skip, setSkip] = useState(0);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    useEffect(() => {
        setLoading(true);
        props.listCategoriesRequest({
            is_active: true
        });
    }, []);

    useEffect(() => {
        const object = {...query};
        object.latitude = props.place && props.place.latitude;
        object.longitude = props.place && props.place.longitude;
        object.skip = skip;
        object.limit = limit;
        setQuery(object);
    }, [props.place]);

    const loadNext = () => {
        setLoading(true);
        const object = {};
        let ob = {...skip};
        object.latitude = props.place && props.place.latitude;
        object.longitude = props.place && props.place.longitude;
        object.skip = ob = skip + limit;
        object.limit = limit;
        object.type = PlansSearch.plans && PlansSearch.plans[0].type;
        if(PlansSearch.plans && PlansSearch.plans[0].distance) { 
            object.type = 'near to me'
        }
        object.categories_id = PlansSearch.plans && PlansSearch.plans[0].categories_id;
        setSkip(ob);
        props.searchPlansRequest(object);
    };

    const loadHome = () => {
        setLoading(true);
        const object = {};
        let ob = {...skip};
        object.latitude = props.place && props.place.latitude;
        object.longitude = props.place && props.place.longitude;
        object.skip = ob = 0;
        object.limit = limit;
        object.type = PlansSearch.plans && PlansSearch.plans[0].type;
        if(PlansSearch.plans && PlansSearch.plans[0].distance) { 
            object.type = 'near to me'
        }
        object.categories_id = PlansSearch.plans && PlansSearch.plans[0].categories_id;
        setSkip(ob);
        props.searchPlansRequest(object);
    };

    useEffect(() => {
        if(props.CategoriesList.categories && props.CategoriesList.categories.length > 0) {
            setLoading(true);
            props.searchPlansRequest({
                type: 'near to me',
                categories_id: props.CategoriesList.categories[0].categories_id,
                latitude: query.latitude,
                longitude: query.longitude,
                limit,
                skip
            });
        }
    }, [props.CategoriesList]);

    useEffect(() => {
        if (isSearch === true) {
            setLoading(true);
            props.searchPlansRequest(query);
        }
    }, [isSearch]);

    useEffect(() => {
        const {success, code, message } = PlansSearch;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
            setSearch(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
            setSearch(false);
        } 
    }, [PlansSearch]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        const object = {...query};
        object[name] = value;
        setQuery(object);
    };

    const handleCategories = (valueMixed) => {
        const object = {...query};
        object.categories_id = valueMixed.length ? valueMixed[0].categories_id: [];
        const name = valueMixed.length ? [valueMixed[0].name]: [];
        setQuery(object);
        setName(name);
    };

    const handleVenue = (valueMixed) => {
        const object = {...query};
        object.type = valueMixed.length ? valueMixed[0].name: [];
        const venue = valueMixed.length ? [valueMixed[0].name]: [];
        setQuery(object);
        setVenue(venue);
    };

    if (PlansSearch.plans && PlansSearch.plans.length === 0) {
        return (
            <React.Fragment>
                {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
                <Confirmation type={'SBranch'} />
            </React.Fragment>
        )
    }
    
    return (
        <React.Fragment>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <PageHeader title={'Search Results'} show={loading} className={'mb-4'}/>
        <Row>
            {PlansSearch.plans && PlansSearch.plans.map((item, key) => {
                const total_price = parseInt(item.schedules[0].charge_fee) > 0 ?
                item.schedules[0].price + (item.schedules[0].price * (item.schedules[0].charge_fee / 100 )) 
                : item.schedules[0].price;
                return (
                    <Col md={3} sm={4} className={'pt-3'}>
                    <Card >
                        <Card.Img src={`https://placehold.co/100x50/orange/white?text=${item.name}`} />
                        <Card.Body>
                            <p>{item.name} <span className={'mr-auto float-right auto-right'}>{item.branch && item.branch.name}</span> </p>
                            <span>{'starting:' + moment(item.start_date).format('DD-MM-YYYY').toString()}</span>
                            <span>{'   ' + 'end:' + moment(item.end_date).format('DD-MM-YYYY').toString()}</span>
                            {item.distance && <p>Distance: {item.distance.toFixed(2)} km </p>}
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                                <Col md={4}>
                                <Link className={'btn btn-danger'} to={`/plans/${item.plans_id}`}><FiLink2/></Link>
                                </Col>
                                <Col md={8} className={'justify-content-end'}>
                                    <h5><Badge bg="secondary">{item.schedules && total_price + ' ' + item.schedules[0].currency}</Badge></h5>
                                </Col>
                            </Row>
                            
                        </Card.Footer>
                    </Card>
                    </Col>
                    )
                })}    
        </Row>
        { PlansSearch.plans && PlansSearch.plans.length === limit &&
        <Row>
            <Col className={'pt-5 mb-5'}>
            <Card>
            <Card.Footer>
                <Button variant={'success'} className={'float-right'} onClick={() => loadNext()}>Load Next</Button>
            </Card.Footer>
            </Card>
            </Col>
        </Row>
        }
        { PlansSearch.plans && PlansSearch.plans.length < limit &&
        <Row>
            <Col className={'pt-5 mb-5'}>
            <Card>
            <Card.Footer>
                <Button variant={'danger'} onClick={() => loadHome()}>Load Home</Button>
            </Card.Footer>
            </Card>
            </Col>
        </Row>
        }
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      place: state.place && state.place.place, 
      CategoriesList: state.categories.list || {}, 
      PlansSearch: state.plans.search || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchPlansRequest,
        listCategoriesRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(HomePlace);