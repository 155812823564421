import https from '../../axios';
export const doInsertTransaction = async (params) => {
    const {
        from_id, schedule_id, price
    } = params;
    try {
        return await https.post('/transaction/insert', {
            from_id, schedule_id, price
        });
    } catch(error) {
        return error;
    }
}

export const doGetTransaction = async (params) => {
    const { transaction_id } = params;
    try {
        return await https.post('/transaction/get', { transaction_id});
    } catch(error) {
        return error;
    }
}

export const doUserTransaction = async (params) => {
    try {
        return await https.post('/transaction/user', params);
    } catch(error) {
        return error;
    }
}
