import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead';
import PhoneCodeJson from '../../../data/PhoneCode/index.json';
import { insertCompaniesRequest, getCompaniesRequest, updateCompaniesRequest, initCompaniesRequest } from '../../../db/action/companies';
import File from '../../File';
import Categories from '../../Categories';
import Domain from '../../Domain';
import Confirmation from '../../Confirmation';
import { HiOutlineMail } from 'react-icons/hi';
import { CgRename, CgShortcut, CgAdidas, CgAlignMiddle, CgArrowLeftR } from 'react-icons/cg';
import { TbAddressBook, TbAbacus } from 'react-icons/tb';
import { TbCircleDot } from 'react-icons/tb';
import { FcPhone, FcGlobe, FcLink } from 'react-icons/fc';
import {TbGenderEpicene} from 'react-icons/tb';
import {isUndefined, isEmpty } from 'lodash';
import isNumeric from 'validator/es/lib/isNumeric';
import isEmail from 'validator/es/lib/isEmail';
import PageHeader from '../../PageHeader';
import Error from '../../Error';

const ECompanies = (props) => {
    const {setIndex, login, CompaniesCreate, CompaniesUpdate, CompaniesGet, companies_id } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [connect_companies_id, setConnectCompaniesId] = useState();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    const [companies, setCompanies] = useState({ 
        name: '',
        short_name: '',
        about: '',
        url: '',
        video_url: '',
        phone: '',
        email: '',
        country: [],
        phoneCode: [],
        created_by: login.user_id,
        companies_id
    });
    
    useEffect(() => {
        if (isEmpty(companies_id) === false) {
            setLoading(true);
            props.getCompaniesRequest({
                companies_id
            });
        }
    }, []);

    const handlePhoneCode = (valueMixed) => {
        const object = {...companies};
        object.phoneCode = valueMixed.length ? [valueMixed[0].dial_code]: [];
        setCompanies(object); 
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        const object = {...companies};
        object[name] = value;
        setCompanies(object);
    };

    const handleCountry = (valueMixed) => {
        const object = {...companies};
        object.country = valueMixed.length ? [valueMixed[0].code]: [];
        setCompanies(object); 
    };

    const handleCompaniesInfo = () => {
        if (isEmpty(companies.name) === true) {
            setInfo({
                ...info,
                  content: 'Please specify Company Name',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(companies.short_name) === true) {
            setInfo({
                ...info,
                  content: 'Please specify Short name of the Company',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (companies.short_name.length !== 4) {
            setInfo({
                ...info,
                  content: 'Short name must be 4 characters',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(companies.about) === true) {
            setInfo({
                ...info,
                  content: 'Please Describe your company in 50 words',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(companies.url) === true) {
            setInfo({
                ...info,
                  content: 'Please Give me Companies Website URL',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(companies.video_url) === true) {
            setInfo({
                ...info,
                  content: 'Please give me Company Video URL',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(companies.country) === true) {
            setInfo({
                ...info,
                  content: 'Please Select the Country',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmail(companies.email) === false) {
            setInfo({
                ...info,
                  content: 'Invalid Email',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
       }
        if (isEmpty(companies.phoneCode) === true) {
            setInfo({
                ...info,
                content: 'Invalid Phone Code. Please Choose from the List',
                variant: 'warning'
            });
            setShowError(true);
            return;  
       }
       if (isNumeric(companies.phone) === false) {
        setInfo({
            ...info,
              content: 'Invalid Phone Number. Please Give only Numbers',
              variant: 'warning'
        });
        setShowError(true);
        return;  
       }

       setLoading(true);
       const data = {...companies};
       data.country = data.country[0];
       data.phoneCode = data.phoneCode[0];
       
       if (isUndefined(companies_id) === false) {
        props.updateCompaniesRequest(data);
       } else {
        props.insertCompaniesRequest(data);
       }
       return;
    }
    
    useEffect(() => {
        const {success, code, message, company} = CompaniesCreate;
        if(isUndefined(success)) {
            return false;
        }

        if(success === true) { 
            setConnectCompaniesId(company.id); 
            setActiveIndex(1);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [CompaniesCreate]);

    useEffect(() => {
        const {success, code, message, companies} = CompaniesUpdate;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setConnectCompaniesId(companies.id);
            setActiveIndex(1); 
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [CompaniesUpdate]);

    useEffect(() => {
        const { company, success, message } = CompaniesGet;
        if(isUndefined(success)) {
            return false;
        }
        if(isUndefined(company && company.name) === false) {
            const object = {...company};
            object.country = [object.country];
            object.phoneCode = [object.phoneCode || ''];
            setIsDone(false);
            setCompanies(object);
            setLoading(false);
        }
    }, [CompaniesGet]);

    if(isDone === true) {
        return (
            <Confirmation title={'Company Registered'} type={'Companies'} />
        )
    };

    // basic 1
    if(activeIndex === 0) {
        return (
            <React.Fragment>
            <PageHeader title={`${!!(companies_id) === true ? 'Update Company or Individual' : 'Register Company or Individual'}`} show={loading} className={'mb-4'}/>
            {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
            <Card border={'light'} className={'border-0'}>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><CgRename /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Name'} 
                        defaultValue={companies.name} 
                        name={'name'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><CgShortcut /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        maxLength={4}
                        placeholder={'Short Name'} 
                        defaultValue={companies.short_name} 
                        name={'short_name'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><TbGenderEpicene /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        as="textarea" rows={3}
                        className={'border-0'} required
                        placeholder={'About'} 
                        defaultValue={companies.about} 
                        name={'about'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcLink /></InputGroup.Text>
                    <Form.Control
                        className={'border-0'} required 
                        placeholder={'Company website URL'} 
                        defaultValue={companies.url} 
                        name={'url'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><TbAddressBook /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Video URL'} 
                        defaultValue={companies.video_url} 
                        name={'video_url'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiOutlineMail/></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Email'} 
                        defaultValue={companies.email} 
                        name={'email'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcPhone /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-phone-code'
                        style={{width: '20%'}}
                        className={'border-0'}
                        labelKey="dial_code"
                        onChange={(value) => handlePhoneCode(value)}
                        options={PhoneCodeJson}
                        placeholder="Code"
                        selected={companies.phoneCode}
                    />
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Phone'} 
                        defaultValue={companies.phone} 
                        name={'phone'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcGlobe /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-country-code'
                        className={'border-0'}
                        labelKey="code"
                        onChange={(value) => handleCountry(value)}
                        options={PhoneCodeJson}
                        placeholder="Country Code"
                        selected={companies.country}
                    /> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mt-2'}>
                    <Row>
                        <Col md={4}>
                        <Button className={'btn btn-primary'}
                            onClick={() => handleCompaniesInfo()}
                        >Next</Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Card>
            </React.Fragment>
        )
    } 

    // file
    if(activeIndex === 1) {
        return (
            <React.Fragment>
                <File 
                    buttonName={'Next'} 
                    setActiveIndex={setActiveIndex} 
                    indexNumber={2} 
                    connect_id={connect_companies_id} />
            </React.Fragment>
        )
    }

    // domain
    if(activeIndex === 2) {
        return (
            <React.Fragment>
                <Domain 
                type={'companies'}
                buttonName={'Complete'} 
                setIsDone={setIsDone}  
                type_id={connect_companies_id} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      CompaniesGet: state.companies.get || {},
      CompaniesCreate: state.companies.insert || {},
      CompaniesUpdate: state.companies.update || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getCompaniesRequest,
        updateCompaniesRequest,
        insertCompaniesRequest,
        initCompaniesRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ECompanies);