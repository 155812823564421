import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, ListGroup, ListGroupItem, Badge} from 'react-bootstrap';
import { Link, useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isEmpty, isUndefined } from 'lodash';
import { getPlansRequest } from '../../../db/action/plans';
import { listCategoriesRequest } from '../../../atom/categories/action';
import { insertTransactionRequest } from '../../../db/action/transaction';
import { userWalletRequest } from '../../../db/action/wallet';
import Error from '../../Error';
import PageHeader from '../../PageHeader';
import Confirmation from '../../Confirmation';

const VPlans = (props) => {
    const {login, PlansGet, plans_id, TransactionInsert, WalletUser } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [connect_plans_id, setConnectPlansId] = useState();
    const navigate = useNavigate();
    const [branch, transferBranch] = useState({});
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    const [plans, setPlans] = useState({ 
        created_by: login.user_id
    });
    
    useEffect(() => {
        props.listCategoriesRequest({
            is_active: true
        });
        props.userWalletRequest({
            created_by: props.login.user_id
        })
        if (isEmpty(plans_id) === false) {
            setLoading(true);
            props.getPlansRequest({
                plans_id
            });
        }
    }, []);
    
    useEffect(() => {
        const { success, message, code, plans } = PlansGet;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setLoading(false);
            setPlans(plans);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        }
    }, [PlansGet]);

    if (TransactionInsert.transaction && TransactionInsert.transaction.id) {
        return (<Confirmation title={'Payment Confirmation'} type={'VPlans'} />);
    }

    if(!plans.categories) {
        return(<React.Fragment />)
    }
    return (<React.Fragment>
            <Card>
                <Card.Body>
                    <Row className={'justify-content-end mb-5'}>
                        <Col md={2}>
                            <Link className={'btn btn-sm btn-primary text-right'} to={'/'}><small>Home</small></Link>
                        </Col>
                    </Row>
                    <Row>
                    <Col md={'6'}>
                        <PageHeader title={`${plans.name}`} show={loading}/>
                        <Badge>{plans.code}</Badge><br/><br/>
                        <Badge bg={'success'}>{plans.branch.name}</Badge><br/><br/>
                        <Badge bg={'danger'}>{plans.categories.name}</Badge><br/><br/>
                    </Col>
                    <Col md={'6'}>
                        <p>{plans.line1}</p>
                        <p>{plans.line2}</p>
                        <p>{plans.city}</p>
                        <p>{plans.state}, {plans.country}</p>
                        <p>{'lat:' + plans.location.coordinates[0]}, {'long:' + plans.location.coordinates[1]}</p>
                        <p>{plans.phoneCode}{plans.phone}</p>
                        <p>Wallet Balance : {WalletUser.wallet && WalletUser.wallet.balance}{ }{'INR'}</p>
                    </Col>
                    </Row>
                    <Row className={'mb-4'}>
                    <Col md={'5'}>
                        <h5>Plan Dates</h5>
                        <span><b>{moment(plans.start_date).format('DD-MM-YYYY').toString()}{' to '}
                        {moment(plans.end_date).format('DD-MM-YYYY').toString()}</b></span>
                    </Col>
                    </Row>   
                    <Row>
                    <Col md={'5'}>
                        <h5>Summary</h5>
                        <span>{plans.summary}</span>
                    </Col>
                    </Row>  
                    <Row>
                    {plans.schedules.map((sch) =>     
                    <Col md={'3'}>
                        <Card bg={'secondry'}>
                            <Card.Header>{sch.type}</Card.Header>
                            <Card.Footer>
                                <Button 
                                className={'btn btn-sm btn-primary'} 
                                onClick={() => {
                                    props.login.user_id ?
                                    WalletUser.wallet.balance > 0 &&   
                                    WalletUser.wallet.balance > (sch.price + (sch.price * (sch.charge_fee / 100 ))) 
                                    ?
                                    props.insertTransactionRequest({
                                        schedule_id: sch.schedule_id,
                                        from_id: props.login.user_id,
                                        price: sch.price + (sch.price * (sch.charge_fee / 100 )) 
                                    }) : navigate('/menu/purchase') : navigate('/login')
                                }}>{
                                    parseInt(sch.charge_fee) > 0 ?
                                    sch.price + (sch.price * (sch.charge_fee / 100 ))    
                                    :sch.price
                                }
                                {' ' + sch.currency}</Button>
                            </Card.Footer>
                        </Card>
                    </Col>)}
                    </Row>         
                </Card.Body>
            </Card>

        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
    </React.Fragment>)
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      CategoriesList: state.categories.list || {},
      TransactionInsert: state.transaction.insert || {},
      WalletUser: state.wallet.user || {},
      PlansGet: state.plans.get || {},
      place: state.place && state.place.place
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getPlansRequest,
        listCategoriesRequest,
        insertTransactionRequest,
        userWalletRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(VPlans);