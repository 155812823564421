import React, {useEffect, useState} from 'react';
import './index.scss';
import showSvg from '../../asset/images/ew-login-layout.svg';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import LSchedule from '../../components/Schedule/List';
import ESchedule from '../../components/Schedule/Edit';
const ScheduleLayout = (props) => {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [schedule_id, setScheduleId] = useState();
    
    useEffect(() => {
        if (props.isMenu === true) {
            setIndex(0);
        }
    }, [props])

    return (
    <>    
    <Container fluid className={'ew-schedule'}>
        <Row className={'justify-content-center pt-4'}>
            {index === 0 && <Col md={12}><LSchedule {...props} setIndex={setIndex} setScheduleId={setScheduleId} /></Col>}
            {index === 1 && <Col md={4}><ESchedule {...props} setIndex={setIndex} schedule_id={schedule_id}/></Col>}
            
        </Row>
    </Container>
    </>
    );
}

export default ScheduleLayout;