import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import './index.scss';
import logoPng from '../../asset/images/logo.png';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import LPlans from '../../components/Plans/List';
import EPlans from '../../components/Plans/Edit';
import VPlans from '../../components/Plans/View';
const PlansLayout = (props) => {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [plans_id, setPlansId] = useState();
    const params = useParams();
    useEffect(() => {
        if (props.isMenu === true) {
            setIndex(0);
        }
    }, [props])

    if(params.plans_id && params.plans_id !== '') {
        return (
            <>    
            <Container fluid className={'ew-login-layout'}>
            <Row className={'align-items-center vh-100'}>
                <Col md={5} className={'ew-bg vh-100'}>
                    <Row className={'justify-content-start align-items-center vh-100'}>
                    <Col md={6} className={'m-5 p-5'}>
                        {show === false && <Spinner color={'#101010'} />}
                        <Image src={logoPng} onLoad={() => setShow(true)} className={'img-fluid'} />
                        <center><div className={'text-white'}><h4>iChase</h4></div></center>
                    </Col>
                </Row>       
                </Col>    
                <Col md={5}><VPlans {...props} plans_id={params.plans_id}/></Col>
                </Row>
            </Container>
            </>
            );
    } 

    return (
    <>    
    <Container fluid className={'ew-plans'}>
        <Row className={'justify-content-center vh-100 align-item-center pt-3'}>
            {index === 0 && <Col md={12}><LPlans {...props} setIndex={setIndex} setPlansId={setPlansId} /></Col>}
            {index === 1 &&  <Col md={4}><EPlans {...props} setIndex={setIndex} plans_id={plans_id}/></Col>}
            {params.plans_id && <Col md={4}><VPlans {...props} plans_id={params.plans_id}/></Col>}
        </Row>
    </Container>
    </>
    );
}

export default PlansLayout;