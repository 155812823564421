import React, {useState, useEffect} from 'react';
import './index.scss';
import showSvg from '../../../asset/images/ew-login-layout.svg';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import ListCategories from '../components/List';
import EditCategories from '../components/Edit';
const CategoriesLayout = (props) => {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [categories_id, setCategoriesId] = useState();
    return (
    <>    
    <Container fluid className={'ew-categories'}>
        <Row className={'justify-content-center pt-4'}>
            {index === 0 && <Col md={8}><ListCategories {...props} setIndex={setIndex} setCategoriesId={setCategoriesId} /></Col>}
            {index === 1 && <Col md={6}><EditCategories {...props} setIndex={setIndex} categories_id={categories_id}/></Col>}
        </Row>
    </Container>
    </>
    );
}

export default CategoriesLayout;