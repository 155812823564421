import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FcTodoList } from 'react-icons/fc';
import { listPlansRequest } from '../../../db/action/plans';
import PageHeader from '../../PageHeader';
import Error from '../../Error';
const SPlans = (props) => {
    const {PlansList, indexNumber, transferPlans, selectedPlansId, setActiveIndex, plans, login, isPublic} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listPlans, setListPlans] = useState([]);
    const [Plans, setPlans] = useState();
    const [name, setName] = useState([]);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    useEffect(() => {
        setLoading(true);
        props.listPlansRequest({created_by: login.user_id, is_public: isPublic});
        if(props.plans) {
            setPlans(props.plans);
            setName([props.plans.name]);
        }
    }, []);

    useEffect(() => {
        const {success, code, message, plansList} = PlansList;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
            setListPlans(plansList);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [PlansList]);

    const handlePlansId = (valueMixed) => {
        let object = {...Plans};
        object = valueMixed.length ? valueMixed[0]: [];
        setPlans(object); 
        setName([object.name]);
    };  
    return (
        <React.Fragment>
        <PageHeader title={'Search Plans'} show={loading} className={'mb-4'}/>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <Card>
            <Card.Body>
            <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcTodoList /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-plans-id'
                        style={{width: '60%'}}
                        className={'border-0'}
                        labelKey="name"
                        onChange={(value) => handlePlansId(value)}
                        options={listPlans}
                        placeholder="Plans Name"
                        selected={name}
                    /> 
                    </InputGroup>
                </Form.Group>
        </Card.Body>
        </Card>
        {indexNumber && <Card border={'light'} className={'border-0 mt-5'}>
            <Form.Group className={'justify-content-end'}>
                <Button onClick={() => { transferPlans(Plans); setActiveIndex(indexNumber);}}>
                    {props.buttonName}
                  </Button>
            </Form.Group>
        </Card>}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      PlansList: state.plans.list || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        listPlansRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(SPlans);