import {call, put, takeLatest} from 'redux-saga/effects';
import {
    INSERT_CASHFREE_REQUEST,
    INSERT_CASHFREE_SUCCESS,
    UPDATE_CASHFREE_REQUEST,
    UPDATE_CASHFREE_SUCCESS,
    GET_CASHFREE_REQUEST,
    GET_CASHFREE_SUCCESS,
    SEARCH_CASHFREE_REQUEST,
    SEARCH_CASHFREE_SUCCESS,
    LIST_CASHFREE_REQUEST,
    LIST_CASHFREE_SUCCESS,
    REMOVE_CASHFREE_REQUEST,
    REMOVE_CASHFREE_SUCCESS
} from '../actionTypes';

import { doInsertCashfree, doGetCashfree, doSearchCashfree,
    doListCashfree, doRemoveCashfree,
    doUpdateCashfree } from '../com';

function* insertCashfreeFetch(params) {
    const json = yield call(doInsertCashfree, params.payload);   
    yield put({ type: INSERT_CASHFREE_SUCCESS, payload: json.data});
}

function* updateCashfreeFetch(params) {
    const json = yield call(doUpdateCashfree, params.payload);   
    yield put({ type: UPDATE_CASHFREE_SUCCESS, payload: json.data});
}

function* getCashfreeFetch(params) {
    const json = yield call(doGetCashfree, params.payload);   
    yield put({ type: GET_CASHFREE_SUCCESS, payload: json.data});
}

function* searchCashfreeFetch(params) {
    const json = yield call(doSearchCashfree, params.payload);   
    yield put({ type: SEARCH_CASHFREE_SUCCESS, payload: json.data});
}

function* listCashfreeFetch(params) {
    const json = yield call(doListCashfree, params.payload);
    yield put({ type: LIST_CASHFREE_SUCCESS, payload: json.data});
}

function* removeCashfreeFetch(params) {
    const json = yield call(doRemoveCashfree, params.payload);   
    yield put({ type: REMOVE_CASHFREE_SUCCESS, payload: json.data});
}

export function* insertCashfreeSaga() {
    yield takeLatest(INSERT_CASHFREE_REQUEST, insertCashfreeFetch)
}

export function* updateCashfreeSaga() {
    yield takeLatest(UPDATE_CASHFREE_REQUEST, updateCashfreeFetch)
}

export function* removeCashfreeSaga() {
    yield takeLatest(REMOVE_CASHFREE_REQUEST, removeCashfreeFetch)
}

export function* getCashfreeSaga() {
    yield takeLatest(GET_CASHFREE_REQUEST, getCashfreeFetch)
}

export function* searchCashfreeSaga() {
    yield takeLatest(SEARCH_CASHFREE_REQUEST, searchCashfreeFetch)
}

export function* listCashfreeSaga() {
    yield takeLatest(LIST_CASHFREE_REQUEST, listCashfreeFetch)
}

