import React, {useState} from 'react';
import './index.scss';
import {Container, Row, Col } from 'react-bootstrap';
import LRevenue from '../../components/Revenue/List';
const RevenueLayout = (props) => {
    const [show, setShow] = useState(false);
    return (
    <>    
    <Container fluid className={'ew-revenue'}>
        <Row className={'justify-content-center'}>
            <Col md={8}><LRevenue {...props} /></Col>
        </Row>
    </Container>
    </>
    );
}

export default RevenueLayout;