import https from '../../axios';

export const doBusinessEarnings = async (params) => {
    const { created_by } = params;
    try {
        return await https.post('/wallet/business', {created_by});
    } catch(error) {
        return error;
    }
}

export const doBusinessEarningsHistory = async (params) => {
    const { created_by } = params;
    try {
        return await https.post('/wallet/history/business', {created_by});
    } catch(error) {
        return error;
    }
}