import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead';
import { 
    updateCategoriesRequest, 
    getCategoriesRequest, 
    insertCategoriesRequest } from '../../action';
import Confirmation from '../../../../components/Confirmation';
import { HiOutlineMail } from 'react-icons/hi';
import { CgRename, CgShortcut, CgArrowLeftR, CgBell, CgAirplane } from 'react-icons/cg';
import { TbAddressBook, TbAccessPoint } from 'react-icons/tb';
import { TbCircleDot, TbAffiliate, Tb3DCubeSphere } from 'react-icons/tb';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import {TbGenderEpicene, TbAlertTriangle} from 'react-icons/tb';
import {isUndefined, isEmpty } from 'lodash';
import isNumeric from 'validator/es/lib/isNumeric';
import isEmail from 'validator/es/lib/isEmail';
import PageHeader from '../../../../components/PageHeader';
import Error from '../../../../components/Error';
const EditCategories = (props) => {
    const { CategoriesInsert, CategoriesUpdate, CategoriesGet, categories_id, setIndex, login} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    const [categories, setCategories] = useState({ 
        name: '',
        icon: '',
        about: '',
        is_active: true,
        created_by: login.user_id
    });
    
    useEffect(() => {
        if (categories_id) {
            setLoading(true);           
            props.getCategoriesRequest({
                categories_id
            });
        }
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        const object = {...categories};
        object[name] = value;
        setCategories(object);
    };

    const handleSubmit = () => {
        if (isEmpty(categories.name) === true) {
            setInfo({
                ...info,
                  content: 'Please specify Categories Name',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(categories.icon) === true) {
            setInfo({
                ...info,
                  content: 'Please specify Categories Icon',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(categories.about) === true) {
            setInfo({
                ...info,
                  content: 'Please specify Category Description',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
       setLoading(true);
       const data = {...categories};
       if (isUndefined(categories_id) === false) {
        props.updateCategoriesRequest(data);
       } else {
        props.insertCategoriesRequest(data);
       }
    }
    
    useEffect(() => {
        const {success, code, message} = CategoriesInsert;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setIndex(0);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [CategoriesInsert]);

    useEffect(() => {
        const {success, code, message, categories} = CategoriesUpdate;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setIndex(0); 
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [CategoriesUpdate]);

    useEffect(() => {
        const { categories, success, message } = CategoriesGet;
        if(isUndefined(success)) {
            return false;
        }
        if(isUndefined(categories && categories.name) === false) {
            setCategories(categories);
            setLoading(false);
        }
    }, [CategoriesGet]);

    if(isDone === true) {
        return (
            <Confirmation 
                title={'My Categories'} 
                type={'EditCategories'}
                message={'You have completed Categories process. Please Go To Categories View Page'} 
                onClick={() => setIndex(0)} />
        )
    };

    const handleActiveChange = (e) => {
        const {name, value, checked} = e.target;
        const object = {...categories};
        object.is_active = checked;
        setCategories(object);
    };

    return (
        <React.Fragment>
        <PageHeader title={`${!!(categories_id) === true ? 'Update Categories' : 'Register Categories'}`} show={loading} className={'mb-4'}/>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <Card border={'light'} className={'border-0'}>
            <Form.Group className={'mb-3 border ew-border-gradient'}>
                <InputGroup>
                <InputGroup.Text className={'bg-white border-0'}><CgRename /></InputGroup.Text>
                <Form.Control  size={'lg'} 
                    className={'border-0'} required
                    placeholder={'Name'} 
                    defaultValue={categories.name} 
                    name={'name'} 
                    onChange={(event) => handleChange(event)}/> 
            </InputGroup>
            </Form.Group>
            <Form.Group className={'mb-3 border ew-border-gradient'}>
                        <InputGroup>
                        <InputGroup.Text className={'bg-white border-0'}><TbAlertTriangle /></InputGroup.Text>
                        <Form.Check  
                            type={'switch'}
                            size={'lg'}  
                            className={'border-0 ms-auto p-2'} required
                            label={'Disabled / Active'}
                            checked={categories.is_active}
                            onChange={handleActiveChange}
                        />
                        </InputGroup>
                </Form.Group>
            <Form.Group className={'mb-3 border ew-border-gradient'}>
                <InputGroup>
                <InputGroup.Text className={'bg-white border-0'}><CgAirplane /></InputGroup.Text>
                <Form.Control  size={'lg'} 
                    className={'border-0'} required
                    placeholder={'Icon'} 
                    defaultValue={categories.icon} 
                    name={'icon'} 
                    onChange={(event) => handleChange(event)}/> 
            </InputGroup>
            </Form.Group>
            <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><TbGenderEpicene /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        as="textarea" rows={3}
                        className={'border-0'} required
                        placeholder={'About'} 
                        defaultValue={categories.about} 
                        name={'about'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
            <Form.Group className={'mt-2'}>
                <Row>
                    <Col md={4}>
                    <Button className={'btn btn-primary'}
                        onClick={() => handleSubmit()}
                    >Submit</Button>
                    </Col>
                </Row>
            </Form.Group>
        </Card>
        </React.Fragment>
    )

}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      CategoriesGet: state.categories.get || {},
      CategoriesInsert: state.categories.insert || {},
      CategoriesUpdate: state.categories.update || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getCategoriesRequest,
        updateCategoriesRequest,
        insertCategoriesRequest, 
        getCategoriesRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(EditCategories);