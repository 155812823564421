export const INSERT_CATEGORIES_REQUEST = 'INSERT_CATEGORIES_REQUEST';
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const LIST_CATEGORIES_REQUEST = 'LIST_CATEGORIES_REQUEST';
export const COMPANIES_CATEGORIES_REQUEST = 'COMPANIES_CATEGORIES_REQUEST';
export const REMOVE_CATEGORIES_REQUEST = 'REMOVE_CATEGORIES_REQUEST';
export const INIT_CATEGORIES_REQUEST = "INIT_CATEGORIES_REQUEST";

export const INSERT_CATEGORIES_SUCCESS = 'INSERT_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const LIST_CATEGORIES_SUCCESS = 'LIST_CATEGORIES_SUCCESS';
export const COMPANIES_CATEGORIES_SUCCESS = 'COMPANIES_CATEGORIES_SUCCESS';
export const REMOVE_CATEGORIES_SUCCESS = 'REMOVE_CATEGORIES_SUCCESS';
