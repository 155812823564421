import { combineReducers } from 'redux';
import token from '../db/reducer/token';
import login from '../db/reducer/login';
import logout from '../db/reducer/logout';
import place from '../db/reducer/place';
import registerUser from '../db/reducer/registerUser';
import user from '../db/reducer/user';
import message from '../db/reducer/message';
import profile from '../db/reducer/profile';
import conversion from '../db/reducer/conversion';
import file from '../db/reducer/file';
import transaction from '../db/reducer/transaction';
import domain from '../db/reducer/domain';
import companies from '../db/reducer/companies';
import branch from '../db/reducer/branch';
import plans from '../db/reducer/plans';
import schedule from '../db/reducer/schedule';
import categories from '../atom/categories/reducer';
import interest from '../db/reducer/interest';
import wallet from '../db/reducer/wallet';
import earnings from '../db/reducer/earnings';
import revenue from '../db/reducer/revenue';
import purchase from '../db/reducer/purchase';
import cashfree from '../atom/cashfree/reducer'

const appReducer = combineReducers({
  transaction,
  companies,
  categories,
  cashfree,
  branch,
  plans,
  schedule,
  domain,
  wallet,
  purchase,
  earnings,
  revenue,
  file,
  token,
  login,
  place,
  registerUser,
  user,
  message,
  profile,
  conversion,
  interest,
  logout
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;

