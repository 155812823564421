import React, {useState} from 'react';
import './index.scss';
import { bindActionCreators } from 'redux'
import logoPng from '../../asset/images/logo.png'
import { connect } from 'react-redux'
import {Container, Row, Col} from 'react-bootstrap';
import Header from '../header/home'
import Place from '../../components/Place/Home';
import Search from '../../components/Place/Search';
const DashboardLayout = (props) => {
    const [show, setShow] = useState(false);
    return (
    <>    
    <Container fluid className={'ew-dashboard-header'}>
        <Header login={props.login} />
        <center><img src={logoPng} width={'5%'} /></center>
        <center><p className={'text-white pt-4'}>Pay As you Use</p></center>
        <Search />
    </Container>
    <Container className={'ew-dashboard'}>
        <Row>
            <Col md={12} sm={12} lg={12}>
            <Place />
            </Col>
        </Row>
    </Container>
    </>
    );
}


const mapStateToProps = (state) => {
    return {
      logout: state.logout,
      login: state.login
    }
  };
    
  const mapDispatchToProps = (dispatch) => {
      return bindActionCreators({
      }, dispatch);
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);