export const INSERT_BRANCH_REQUEST = 'INSERT_BRANCH_REQUEST';
export const SEARCH_BRANCH_REQUEST = 'SEARCH_BRANCH_REQUEST';
export const UPDATE_BRANCH_REQUEST = 'UPDATE_BRANCH_REQUEST';
export const GET_BRANCH_REQUEST = 'GET_BRANCH_REQUEST';
export const LIST_BRANCH_REQUEST = 'LIST_BRANCH_REQUEST';
export const COMPANIES_BRANCH_REQUEST = 'COMPANIES_BRANCH_REQUEST';
export const REMOVE_BRANCH_REQUEST = 'REMOVE_BRANCH_REQUEST';
export const INIT_BRANCH_REQUEST = "INIT_BRANCH_REQUEST";

export const INSERT_BRANCH_SUCCESS = 'INSERT_BRANCH_SUCCESS';
export const SEARCH_BRANCH_SUCCESS = 'SEARCH_BRANCH_SUCCESS';
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const LIST_BRANCH_SUCCESS = 'LIST_BRANCH_SUCCESS';
export const COMPANIES_BRANCH_SUCCESS = 'COMPANIES_BRANCH_SUCCESS';
export const REMOVE_BRANCH_SUCCESS = 'REMOVE_BRANCH_SUCCESS';
