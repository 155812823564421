export const INIT_CASHFREE_REQUEST = 'INIT_CASHFREE_REQUEST';
export const INSERT_CASHFREE_REQUEST = 'INSERT_CASHFREE_REQUEST';
export const UPDATE_CASHFREE_REQUEST = 'UPDATE_CASHFREE_REQUEST';
export const GET_CASHFREE_REQUEST = 'GET_CASHFREE_REQUEST';
export const SEARCH_CASHFREE_REQUEST = 'SEARCH_CASHFREE_REQUEST';
export const LIST_CASHFREE_REQUEST = 'LIST_CASHFREE_REQUEST';
export const REMOVE_CASHFREE_REQUEST = 'REMOVE_CASHFREE_REQUEST';

export const INSERT_CASHFREE_SUCCESS = 'INSERT_CASHFREE_SUCCESS';
export const UPDATE_CASHFREE_SUCCESS = 'UPDATE_CASHFREE_SUCCESS';
export const GET_CASHFREE_SUCCESS = 'GET_CASHFREE_SUCCESS';
export const SEARCH_CASHFREE_SUCCESS = 'SEARCH_CASHFREE_SUCCESS';
export const LIST_CASHFREE_SUCCESS = 'LIST_CASHFREE_SUCCESS';
export const REMOVE_CASHFREE_SUCCESS = 'REMOVE_CASHFREE_SUCCESS';
