import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, Table, Image} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FcTodoList } from 'react-icons/fc';
import { listBranchRequest } from '../../../db/action/branch';
import PageHeader from '../../PageHeader';
import Error from '../../Error';
const SBranch = (props) => {
    const {BranchList, indexNumber, transferBranch, selectedBranchId, setActiveIndex} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [branchList, setBranchList] = useState([]);
    const [branch, setBranch] = useState();
    const [name, setName] = useState([]);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    useEffect(() => {
        setLoading(true);
        props.listBranchRequest();
        if(props.branch) {
            setBranch(props.branch);
            setName([props.branch.name]);
        }
    }, []);

    useEffect(() => {
        const {success, code, message, branchList} = BranchList;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
            setBranchList(branchList);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [BranchList]);

    const handleBranchId = (valueMixed) => {
        let object = {...branch};
        object = valueMixed.length ? valueMixed[0]: [];
        setBranch(object);
        setName([object.name]); 
    };  
    return (
        <React.Fragment>
        <PageHeader title={'Search Branch'} show={loading} className={'mb-4'}/>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <Card>
            <Card.Body>
            <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcTodoList /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-branch-id'
                        style={{width: '60%'}}
                        className={'border-0'}
                        labelKey="name"
                        onChange={(value) => handleBranchId(value)}
                        options={branchList}
                        placeholder="Branch Name"
                        selected={name}
                    /> 
                    </InputGroup>
                </Form.Group>
        </Card.Body>
        </Card>
        {indexNumber && <Card border={'light'} className={'border-0 mt-5'}>
            <Form.Group className={'justify-content-end'}>
                <Button onClick={() => { transferBranch(branch); setActiveIndex(indexNumber);}}>
                    {props.buttonName}
                  </Button>
            </Form.Group>
        </Card>}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      BranchList: state.branch.list || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        listBranchRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(SBranch);