import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TiInfo } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'react-bootstrap';
const Instruction = ({type, title, setIndex}) => {
    return (
        <React.Fragment>
            <Row className={'bg-instruction p-3 mt-2 mb-2 border-1'}>
                <Col md={12}>
            {type === 'infoActivateUser' && 
                <React.Fragment> 
                <div><TiInfo size={20} /><span> Please check your mail. You must receive an OTP from us.</span></div>   
            </React.Fragment>
            }
            </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {}
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Instruction);