import { 
    INSERT_PLANS_REQUEST,
    SEARCH_PLANS_REQUEST,
    UPDATE_PLANS_REQUEST,
    GET_PLANS_REQUEST,
    LIST_PLANS_REQUEST,
    BRANCH_PLANS_REQUEST,
    REMOVE_PLANS_REQUEST,
    INIT_PLANS_REQUEST
} from '../../actionTypes/plans';

export const initPlansRequest = (params) => {
  return {
    type: INIT_PLANS_REQUEST,
    payload:params
  };
};

export const insertPlansRequest = (params) => {
  return {
    type: INSERT_PLANS_REQUEST,
    payload:params
  };
};


export const searchPlansRequest = (params) => {
  return {
    type: SEARCH_PLANS_REQUEST,
    payload:params
  };
};

export const updatePlansRequest = (params) => {
  return {
    type: UPDATE_PLANS_REQUEST,
    payload:params
  };
};

export const getPlansRequest = (params) => {
  return {
    type: GET_PLANS_REQUEST,
    payload:params
  };
};

export const listPlansRequest = (params) => {
  return {
    type: LIST_PLANS_REQUEST,
    payload:params
  };
};

export const branchPlansRequest = (params) => {
  return {
    type: BRANCH_PLANS_REQUEST,
    payload:params
  };
};

export const removePlansRequest = (params) => {
  return {
    type: REMOVE_PLANS_REQUEST,
    payload:params
  };
};
