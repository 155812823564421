import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead';
import currencyCodeJson from '../../../data/Currency/index.json';
import { insertScheduleRequest, getScheduleRequest, updateScheduleRequest, initScheduleRequest } from '../../../db/action/schedule';
import SPlans from '../../Plans/Search';
import Confirmation from '../../Confirmation';
import { HiArchive, HiArrowCircleRight, HiOutlineMail } from 'react-icons/hi';
import { CgRename, CgShortcut, CgAdidas, CgAlignMiddle, CgArrowLeftR, CgAirplane, CgArrowLongRightR, CgArrowBottomRight, CgCalendar } from 'react-icons/cg';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import DatePicker from 'react-datepicker';
import { FcPhone, FcGlobe, FcLink } from 'react-icons/fc';
import {TbGenderEpicene} from 'react-icons/tb';
import {isUndefined, isEmpty } from 'lodash';
import isNumeric from 'validator/es/lib/isNumeric';
import isEmail from 'validator/es/lib/isEmail';
import PageHeader from '../../PageHeader';
import Error from '../../Error';

currencyCodeJson = Object.keys(currencyCodeJson).map((item) => {
    return { code: currencyCodeJson[item].code }
});

const ESchedule = (props) => {
    const {login, ScheduleCreate, ScheduleUpdate, ScheduleGet, schedule_id } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [connect_schedule_id, setConnectScheduleId] = useState();
    const navigate = useNavigate();
    const [plans, transferPlans] = useState({});
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    const [schedule, setSchedule] = useState({ 
        plans_id:'',
        type:'',
        price:'',
        currency:[],
        created_by: login.user_id
    });
    
    useEffect(() => {
        if (isEmpty(schedule_id) === false) {
            setLoading(true);
            props.getScheduleRequest({
                schedule_id
            });
        }
    }, []);

    const handleType = (valueMixed) => {
        const object = {...schedule};
        object.type = valueMixed.length ? [valueMixed[0].name]: [];
        setSchedule(object); 
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        const object = {...schedule};
        object[name] = value;
        setSchedule(object);
    };

    const handleCurrencyCode = (valueMixed) => {
        const object = {...schedule};
        object.currency = valueMixed.length ? [valueMixed[0].code]: [];
        setSchedule(object); 
    };
    
    const handleScheduleInfo = () => {
        if (isEmpty(plans.id) === true) {
            setInfo({
                ...info,
                  content: 'Please Select Plans',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(schedule.type) === true) {
            setInfo({
                ...info,
                  content: 'Please Select the plan type',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(schedule.price.toString()) === true) {
            setInfo({
                ...info,
                    content: 'Please specify Amount',
                    variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (Number(schedule.price) <= 0) {
            setInfo({
                ...info,
                    content: 'Please specify Amount more than 0.00',
                    variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (schedule.currency[0].length === 0) {
            setInfo({
                ...info,
                  content: 'Please Select your Currency',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
       setLoading(true);
       const data = {...schedule};
       data.plans_id = plans.id;
       data.type = data.type[0];
       data.currency = data.currency[0];
       if (isUndefined(schedule_id) === false) {
        props.updateScheduleRequest(data);
       } else {
        props.insertScheduleRequest(data);
       }
       return;
    }
    
    useEffect(() => {
        const {success, code, message, schedule} = ScheduleCreate;
        if(isUndefined(success)) {
            return false;
        }

        if(success === true) { 
            setConnectScheduleId(schedule.id);            
            setLoading(false);
            setIsDone(true);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [ScheduleCreate]);

    useEffect(() => {
        const {success, code, message, schedule} = ScheduleUpdate;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setConnectScheduleId(schedule.id);
            setLoading(false);
            setIsDone(true);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [ScheduleUpdate]);

    useEffect(() => {
        const { success, message } = ScheduleGet;
        if(isUndefined(success)) {
            return false;
        }
        if(isUndefined(ScheduleGet.schedule && ScheduleGet.schedule.price) === false) {
            const object = {...ScheduleGet.schedule};
            object.type = [object.type || ''];
            object.currency = [object.currency || ''];
            setIsDone(false);
            setSchedule(object);
            setLoading(false);
        }
    }, [ScheduleGet]);

    if(isDone === true) {
        return (
            <Confirmation title={'Schedule Registered'} type={'Schedule'} />
        )
    };

    // search
    if(activeIndex === 0 && !schedule_id) {
        return (
            <React.Fragment>
                <SPlans 
                    buttonName={'Next'} 
                    selectedPlansId
                    setActiveIndex={setActiveIndex}
                    transferPlans={transferPlans} 
                    indexNumber={1} 
                />
            </React.Fragment>
        )
    } 

    if(activeIndex === 0 && schedule.plans_id) {
        return (
            <React.Fragment>
                <SPlans 
                    buttonName={'Next'} 
                    plans={schedule.plans}
                    selectedPlansId={schedule.plans_id}
                    setActiveIndex={setActiveIndex}
                    transferPlans={transferPlans} 
                    indexNumber={1} 
                />
            </React.Fragment>
        )
    }
    // basic 1
    if(activeIndex === 1) {
        return (
            <React.Fragment>
            <PageHeader title={`${!!(schedule_id) === true ? 'Update Schedule' : 'Register Schedule'}`} show={loading} className={'mb-4'}/>
            {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
            <Card border={'light'} className={'border-0'}>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><CgAirplane /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-schedule-type'
                        style={{width: '50%'}}
                        className={'border-0'}
                        labelKey="name"
                        onChange={(value) => handleType(value)}
                        options={[
                            {name: 'session'},
                            {name:'day'},
                            {name: 'week'},
                            {name:'month'},
                            {name: 'year'}
                        ]} 
                        placeholder="Type of the Schedule"
                        selected={schedule.type}
                    />
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                <InputGroup>
                <InputGroup.Text className={'bg-white border-0'}><MdOutlineStickyNote2 /></InputGroup.Text>
                <Form.Control  size={'lg'} 
                    className={'border-0 w-25 p-0'} required
                    placeholder={'Price'} 
                    defaultValue={schedule.price} 
                    name={'price'} 
                    onChange={(event) => handleChange(event)}/>
                <Typeahead
                    multiple={false}
                    id='ew-currency'
                    className={'border-0 w-50'}
                    labelKey="code"
                    onChange={(value) => handleCurrencyCode(value)}
                    options={currencyCodeJson}
                    placeholder="Currency Code"
                    selected={schedule.currency}
                />
                </InputGroup>
            </Form.Group>
                <Form.Group className={'mt-2'}>
                    <Row>
                        <Col md={4}>
                        <Button className={'btn btn-primary'}
                            onClick={() => handleScheduleInfo()}
                        >Submit</Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Card>
            </React.Fragment>
        )
    } 
    return (<React.Fragment></React.Fragment>)
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      ScheduleGet: state.schedule.get || {},
      ScheduleCreate: state.schedule.insert || {},
      ScheduleUpdate: state.schedule.update || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getScheduleRequest,
        updateScheduleRequest,
        insertScheduleRequest,
        initScheduleRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ESchedule);