import React, {useEffect} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {getTokenRequest} from '../db/action/token';
import {registerPlaceRequest} from '../db/action/place';
import Auth from '../layout/auth';
import Footer from '../layout/footer';
import Content from '../layout/content';
import MainMenu from '../components/MainMenu';
import Header from '../components/Header';
import {Spinner, Row, Col, Container} from 'react-bootstrap';

function App(props) {
  
  const navigate = useNavigate();
  useEffect(() => {
    if(!props.token.accessToken) {
      props.getTokenRequest();
    }
  }, []);

  useEffect(() => {
    if(props.logout === null) {
      navigate('/');
    }
  }, [props]);
  
  useEffect(() => {
    const showPosition = (position) => {
      props.registerPlaceRequest({
        place:position.coords
      });
    }
    navigator.geolocation.getCurrentPosition(showPosition);
  }, []);

  return props.token.accessToken ? ( 
    <div className={`main-wrapper`}>
      <Auth>
        <Content/>
      </Auth>
      <Footer/>
    </div>
  ) : <Container fluid><Row className={' align-items-center justify-content-center'}>
        <Col md={1}> 
        <Spinner animation={'border'} variant={'success'}/>
        </Col>
      </Row></Container>;
}

const mapStateToProps = (state) => {
  return {
    logout: state.logout,
    token: state.token,
    login: state.login
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getTokenRequest,
    registerPlaceRequest
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);