import React, {useState} from 'react';
import './index.scss';
import showSvg from '../../asset/images/ew-login-layout.svg';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import LEarnings from '../../components/Earnings/List';
const EarningsLayout = (props) => {
    const [show, setShow] = useState(false);
    return (
    <>    
    <Container fluid className={'ew-earnings'}>
        <Row className={'justify-content-center'}>
            <Col md={8}><LEarnings {...props} /></Col>
        </Row>
    </Container>
    </>
    );
}

export default EarningsLayout;