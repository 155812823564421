import { 
    INSERT_SCHEDULE_REQUEST,
    UPDATE_SCHEDULE_REQUEST,
    GET_SCHEDULE_REQUEST,
    LIST_SCHEDULE_REQUEST,
    PLANS_SCHEDULE_REQUEST,
    REMOVE_SCHEDULE_REQUEST,
    INIT_SCHEDULE_REQUEST
} from '../../actionTypes/schedule';

export const initScheduleRequest = (params) => {
  return {
    type: INIT_SCHEDULE_REQUEST,
    payload:params
  };
};

export const insertScheduleRequest = (params) => {
  return {
    type: INSERT_SCHEDULE_REQUEST,
    payload:params
  };
};

export const updateScheduleRequest = (params) => {
  return {
    type: UPDATE_SCHEDULE_REQUEST,
    payload:params
  };
};

export const getScheduleRequest = (params) => {
  return {
    type: GET_SCHEDULE_REQUEST,
    payload:params
  };
};

export const listScheduleRequest = (params) => {
  return {
    type: LIST_SCHEDULE_REQUEST,
    payload:params
  };
};

export const plansScheduleRequest = (params) => {
  return {
    type: PLANS_SCHEDULE_REQUEST,
    payload:params
  };
};

export const removeScheduleRequest = (params) => {
  return {
    type: REMOVE_SCHEDULE_REQUEST,
    payload:params
  };
};
