import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Col, Row, InputGroup, Form, Button, Card, ListGroup, ListGroupItem} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead';
import PhoneCodeJson from '../../../data/PhoneCode/index.json';
import { insertBranchRequest, getBranchRequest, updateBranchRequest, initBranchRequest } from '../../../db/action/branch';
import File from '../../File';
import SCompanies from '../../Companies/Search';
import Domain from '../../Domain';
import Confirmation from '../../Confirmation';
import { HiArchive, HiArrowCircleRight, HiOutlineMail } from 'react-icons/hi';
import { CgRename, CgShortcut, CgAdidas, CgAlignMiddle, CgArrowLeftR, CgAirplane } from 'react-icons/cg';
import { TbAddressBook, TbAbacus } from 'react-icons/tb';
import { TbCircleDot } from 'react-icons/tb';
import { FcPhone, FcGlobe, FcLink } from 'react-icons/fc';
import {TbGenderEpicene} from 'react-icons/tb';
import {isUndefined, isEmpty } from 'lodash';
import isNumeric from 'validator/es/lib/isNumeric';
import isEmail from 'validator/es/lib/isEmail';
import PageHeader from '../../PageHeader';
import Error from '../../Error';

const EBranch = (props) => {
    const {login, BranchCreate, BranchUpdate, BranchGet, branch_id } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [connect_branch_id, setConnectBranchId] = useState();
    const navigate = useNavigate();
    const [company, transferCompany] = useState({});
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    const [branch, setBranch] = useState({ 
        name:'',  
        code:'', 
        summary:'', 
        payment_upi:'', 
        url:'', 
        video_url:'',
        line1:'',
        line2:'', 
        city:'',
        location:'',
        latitude:'',
        longitude:'',
        url: '',
        video_url: '',
        phone: '',
        email: '',
        country: [],
        phoneCode: [],
        created_by: login.user_id
    });
    
    useEffect(() => {
        if (isEmpty(branch_id) === false) {
            setLoading(true);
            props.getBranchRequest({
                branch_id
            });
        }
    }, []);

    const handlePhoneCode = (valueMixed) => {
        const object = {...branch};
        object.phoneCode = valueMixed.length ? [valueMixed[0].dial_code]: [];
        setBranch(object); 
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        const object = {...branch};
        object[name] = value;
        setBranch(object);
    };

    const handleCountry = (valueMixed) => {
        const object = {...branch};
        object.country = valueMixed.length ? [valueMixed[0].code]: [];
        setBranch(object); 
    };

    const handleBranchInfo = () => {
        if (isEmpty(company[0].id) === true) {
            setInfo({
                ...info,
                  content: 'Please Select Company',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.name) === true) {
            setInfo({
                ...info,
                  content: 'Please specify Company Name',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.code) === true) {
            setInfo({
                ...info,
                  content: 'Please specify Code of the Branch',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.summary) === true) {
            setInfo({
                ...info,
                  content: 'Please Describe about your branch',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.payment_upi) === true) {
            setInfo({
                ...info,
                  content: 'Please Give Payment UPI',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.line1) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify line1',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.line2) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify line2',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.city) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify city',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.latitude.toString()) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify latitude',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.longitude.toString()) === true) {
            setInfo({
                ...info,
                  content: 'Please Specify longitude',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.payment_upi) === true) {
            setInfo({
                ...info,
                  content: 'Please Give me Branch Payment URI',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.url) === true) {
            setInfo({
                ...info,
                  content: 'Please Give me Branch Website URL',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.video_url) === true) {
            setInfo({
                ...info,
                  content: 'Please give me Company Video URL',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmpty(branch.country) === true) {
            setInfo({
                ...info,
                  content: 'Please Select the Country',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
        }
        if (isEmail(branch.email) === false) {
            setInfo({
                ...info,
                  content: 'Invalid Email',
                  variant: 'warning'
            });
            setShowError(true);
            return;  
       }
        if (isEmpty(branch.phoneCode) === true) {
            setInfo({
                ...info,
                content: 'Invalid Phone Code. Please Choose from the List',
                variant: 'warning'
            });
            setShowError(true);
            return;  
       }
       if (isNumeric(branch.phone) === false) {
        setInfo({
            ...info,
              content: 'Invalid Phone Number. Please Give only Numbers',
              variant: 'warning'
        });
        setShowError(true);
        return;  
       }

       setLoading(true);
       const data = {...branch};
       data.companies_id = company[0].id;
       data.country = data.country[0];
       data.phoneCode = data.phoneCode[0];
       data.location = {
            type: 'Point',
            coordinates: [data.longitude, data.latitude]
        }
        if (isUndefined(branch_id) === false) {
        props.updateBranchRequest(data);
       } else {
        props.insertBranchRequest(data);
       }
       return;
    }
    
    useEffect(() => {
        const {success, code, message, branch} = BranchCreate;
        if(isUndefined(success)) {
            return false;
        }

        if(success === true) { 
            setConnectBranchId(branch.id); 
            setActiveIndex(2);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [BranchCreate]);

    useEffect(() => {
        const {success, code, message, branch} = BranchUpdate;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setConnectBranchId(branch.id);
            setActiveIndex(2); 
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [BranchUpdate]);

    useEffect(() => {
        const { success, message, branch } = BranchGet;
        if(isUndefined(success)) {
            return false;
        }
        if(isUndefined(branch && branch.name) === false) {
            const object = {...branch};
            object.country = [object.country];
            object.phoneCode = [object.phoneCode || ''];
            object.latitude = object.location.coordinates[1];
            object.longitude = object.location.coordinates[0];
            setIsDone(false);
            setBranch(object);
            setLoading(false);
        }
    }, [BranchGet]);

    if(isDone === true) {
        return (
            <Confirmation title={'Branch Registered'} type={'Branch'} />
        )
    };

    // search
    if(activeIndex === 0 && !branch_id) {
        return (
            <React.Fragment>
                <SCompanies 
                    buttonName={'Next'}
                    companies={branch.companies} 
                    selectedCompanyId={branch.companies_id}
                    setActiveIndex={setActiveIndex}
                    transferCompany={transferCompany} 
                    indexNumber={1} 
                />
            </React.Fragment>
        )
    } 

    // search
    if(activeIndex === 0 && branch_id && branch.companies) {
        return (
            <React.Fragment>
                <SCompanies 
                    buttonName={'Next'}
                    companies={branch.companies} 
                    selectedCompanyId={branch.companies_id}
                    setActiveIndex={setActiveIndex}
                    transferCompany={transferCompany} 
                    indexNumber={1} 
                />
            </React.Fragment>
        )
    } 

    // basic 1
    if(activeIndex === 1) {
        return (
            <React.Fragment>
            <PageHeader title={`${!!(branch_id) === true ? 'Update Branch' : 'Register Branch'}`} show={loading} className={'mb-4'}/>
            {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
            <Card border={'light'} className={'border-0'}>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><CgRename /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Name'} 
                        defaultValue={branch.name} 
                        name={'name'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><CgAirplane /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Payment UPI'} 
                        defaultValue={branch.payment_upi} 
                        name={'payment_upi'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><CgShortcut /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        maxLength={4}
                        placeholder={'Code'} 
                        defaultValue={branch.code} 
                        name={'code'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><TbGenderEpicene /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        as="textarea" rows={3}
                        className={'border-0'} required
                        placeholder={'Summary'} 
                        defaultValue={branch.summary} 
                        name={'summary'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcLink /></InputGroup.Text>
                    <Form.Control
                        className={'border-0'} required 
                        placeholder={'Company website URL'} 
                        defaultValue={branch.url} 
                        name={'url'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><TbAddressBook /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Video URL'} 
                        defaultValue={branch.video_url} 
                        name={'video_url'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiOutlineMail/></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Email'} 
                        defaultValue={branch.email} 
                        name={'email'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiArchive /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Line1'} 
                        defaultValue={branch.line1} 
                        name={'line1'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiArrowCircleRight /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Line2'} 
                        defaultValue={branch.line2} 
                        name={'line2'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><HiArrowCircleRight /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'City'} 
                        defaultValue={branch.city} 
                        name={'city'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcPhone /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-phone-code'
                        style={{width: '20%'}}
                        className={'border-0'}
                        labelKey="dial_code"
                        onChange={(value) => handlePhoneCode(value)}
                        options={PhoneCodeJson}
                        placeholder="Code"
                        selected={branch.phoneCode}
                    />
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Phone'} 
                        defaultValue={branch.phone} 
                        name={'phone'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcGlobe /></InputGroup.Text>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Latitude'} 
                        defaultValue={branch.latitude} 
                        name={'latitude'} 
                        onChange={(event) => handleChange(event)}/>
                    <Form.Control  size={'lg'} 
                        className={'border-0'} required
                        placeholder={'Longitude'} 
                        defaultValue={branch.longitude} 
                        name={'longitude'} 
                        onChange={(event) => handleChange(event)}/> 
                    </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3 border ew-border-gradient'}>
                    <InputGroup>
                    <InputGroup.Text className={'bg-white border-0'}><FcGlobe /></InputGroup.Text>
                    <Typeahead
                        multiple={false}
                        id='ew-country-code'
                        className={'border-0'}
                        labelKey="code"
                        onChange={(value) => handleCountry(value)}
                        options={PhoneCodeJson}
                        placeholder="Country Code"
                        selected={branch.country}
                    /> 
                    </InputGroup>
                </Form.Group>
                 
                <Form.Group className={'mt-2'}>
                    <Row>
                        <Col md={4}>
                        <Button className={'btn btn-primary'}
                            onClick={() => handleBranchInfo()}
                        >Next</Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Card>
            </React.Fragment>
        )
    } 

    // file
    if(activeIndex === 2) {
        return (
            <React.Fragment>
                <File 
                    buttonName={'Complete'} 
                    setActiveIndex={setActiveIndex} 
                    setIsDone={setIsDone} 
                    connect_id={connect_branch_id} />
            </React.Fragment>
        )
    }

    return (<React.Fragment></React.Fragment>)
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      BranchGet: state.branch.get || {},
      BranchCreate: state.branch.insert || {},
      BranchUpdate: state.branch.update || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getBranchRequest,
        updateBranchRequest,
        insertBranchRequest,
        initBranchRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(EBranch);