import { 
  BUSINESS_EARNINGS_REQUEST,
  BUSINESS_EARNINGS_HISTORY_REQUEST,
} from '../../actionTypes/earnings';

export const businessEarningsRequest = (params) => {
  return {
    type: BUSINESS_EARNINGS_REQUEST,
    payload:params
  };
};

export const businessEarningsHistoryRequest = (params) => {
  return {
    type: BUSINESS_EARNINGS_HISTORY_REQUEST,
    payload:params
  };
};