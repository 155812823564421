import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {Row, Card, Table, Image} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {TbCircleDot} from 'react-icons/tb';
import Confirmation from '../../Confirmation';
import { adminRevenueRequest, adminRevenueHistoryRequest } from '../../../db/action/revenue';
import PageHeader from '../../PageHeader';
import Error from '../../Error';
const LRevenue = (props) => {
    const {HistoryList, login, Revenue} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    useEffect(() => {
        setLoading(true);
        props.adminRevenueRequest({
            created_by: login.user_id
        });
        props.adminRevenueHistoryRequest({
            created_by: login.user_id
        })
    }, []);

    useEffect(() => {
        const {success, code, message} = HistoryList;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [HistoryList]);

    if (HistoryList.history && HistoryList.history.length === 0) {
        return (<Confirmation title={'My Revenue'} type={'LRevenue'} />);
    }

    return (
        <React.Fragment>
        <PageHeader title={''} show={loading} className={'mb-4'}/>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <Card>
        {Revenue.revenue && Revenue.revenue.balance  && <Card.Header className={'p-2 bg-white text-end'}>
            <h4>{Revenue.revenue.balance}{' '}<TbCircleDot /></h4>
        </Card.Header>}
        {Revenue.revenue && Revenue.revenue.balance && <Card.Body>
        <Row>
            <Table responsive="md">
                <thead>
                    <tr>
                        <th></th>
                        <th>consumer</th>
                        <th>Balance</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {HistoryList.history && HistoryList.history.map((item, key) => {
                    return (
                        <tr key={key}>
                            <td><Image src={'https://via.placeholder.com/20'} roundedCircle/></td>
                            <td>{item.consumer && item.consumer.firstName + ' ' + item.consumer.lastName}</td>
                            <td>{item.balance}</td>
                            <td>{item.type}</td>
                            <td>{item.created_date}</td>
                        </tr>
                    )
                    })}    
                    </tbody>
            </Table>
        </Row>
        </Card.Body>}
        </Card>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      login: state.login || {},
      Revenue: state.revenue.admin || {},
      HistoryList: state.revenue.adminHistory || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        adminRevenueRequest, 
        adminRevenueHistoryRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(LRevenue);