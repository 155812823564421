import React, {useEffect, useState} from 'react';
import './index.scss';
import showSvg from '../../asset/images/ew-login-layout.svg';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import LBranch from '../../components/Branch/List';
import EBranch from '../../components/Branch/Edit';
const BranchLayout = (props) => {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [branch_id, setBranchId] = useState();
    
    useEffect(() => {
        if (props.isMenu === true) {
            setIndex(0);
        }
    }, [props])

    return (
    <>    
    <Container fluid className={'ew-branch'}>
        <Row className={'justify-content-center pt-3'}>
            {index === 0 && <Col md={12}><LBranch {...props} setIndex={setIndex} setBranchId={setBranchId} /></Col>}
            {index === 1 && <Col md={4}><EBranch {...props} setIndex={setIndex} branch_id={branch_id}/></Col>}
            
        </Row>
    </Container>
    </>
    );
}

export default BranchLayout;