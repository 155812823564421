import {  
  BUSINESS_EARNINGS_HISTORY_REQUEST, 
  BUSINESS_EARNINGS_HISTORY_SUCCESS,
  BUSINESS_EARNINGS_REQUEST,
  BUSINESS_EARNINGS_SUCCESS,
} from '../../actionTypes/earnings';

const earningsReducer = (state = {}, action) => {
  switch (action.type) {  
    case BUSINESS_EARNINGS_REQUEST:
      state = { ...state, business: {} };
      break;
    case BUSINESS_EARNINGS_HISTORY_REQUEST:
      state = { ...state, businessHistory: {} };
      break;

    case BUSINESS_EARNINGS_SUCCESS:
      state = { ...state,
                business:{
                    ...action.payload
                }
              };        
      break;
      case BUSINESS_EARNINGS_HISTORY_SUCCESS:
        state = { ...state,
                  businessHistory:{
                      ...action.payload  
                  }
              };
        break;   
    default:
      break;
  }
  return state;
};
export default earningsReducer;