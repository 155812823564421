import {  
    INSERT_BRANCH_REQUEST,
    INSERT_BRANCH_SUCCESS,
    SEARCH_BRANCH_REQUEST,
    SEARCH_BRANCH_SUCCESS,
    UPDATE_BRANCH_REQUEST,
    UPDATE_BRANCH_SUCCESS,
    GET_BRANCH_REQUEST,
    GET_BRANCH_SUCCESS,
    COMPANIES_BRANCH_REQUEST,
    COMPANIES_BRANCH_SUCCESS,
    LIST_BRANCH_REQUEST,
    LIST_BRANCH_SUCCESS,
    REMOVE_BRANCH_REQUEST,
    REMOVE_BRANCH_SUCCESS,
    INIT_BRANCH_REQUEST
  } from '../../actionTypes/branch';
  
  const branchReducer = (state = {}, action) => {
    switch (action.type) {  
      case INIT_BRANCH_REQUEST:
        state = { ...state, insert: {}, update: {}, get: [], companies: {} , list: {}, remove: {}, search: {} };
        break;
      case INSERT_BRANCH_REQUEST:
        state = { ...state, insert: {} };
        break;
        case SEARCH_BRANCH_REQUEST:
          state = { ...state, search: {} };
          break;
        case UPDATE_BRANCH_REQUEST:
          state = { ...state, insert: {} };
          break;
      case GET_BRANCH_REQUEST:
          state = { ...state, get: [] };
          break;
      case COMPANIES_BRANCH_REQUEST:
        state = { ...state, companies: {} };
        break;
      case LIST_BRANCH_REQUEST:
          state = { ...state, list: {} };
          break;
      case REMOVE_BRANCH_REQUEST:
        state = { ...state, remove: {} };
        break;
    case INSERT_BRANCH_SUCCESS:
        state = { ...state,
                  insert:{
                      ...action.payload  
                  }
              };
        break;
        case SEARCH_BRANCH_SUCCESS:
          state = { ...state,
                    search:{
                        ...action.payload  
                    }
                };
          break;
        case UPDATE_BRANCH_SUCCESS:
          state = { ...state,
                    update:{
                        ...action.payload  
                    }
                };
          break;  
        case GET_BRANCH_SUCCESS:
        state = { ...state,
                  get:{
                      ...action.payload  
                  }
              };
        break;
      case LIST_BRANCH_SUCCESS:
        state = { ...state,
                  list:{
                      ...action.payload
                  }
              };
      break;        
      case COMPANIES_BRANCH_SUCCESS:
        state = { ...state,
                  companies:{
                      ...action.payload
                  }
                };        
        break;
      case REMOVE_BRANCH_SUCCESS:
        state = { ...state,
                  remove:{
                      ...action.payload
                  }
                };        
        break;
      default:
        break;
    }
    return state;
  };
  export default branchReducer;