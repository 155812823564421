import {  
    INIT_CASHFREE_REQUEST,
    INSERT_CASHFREE_REQUEST,
    INSERT_CASHFREE_SUCCESS,
    UPDATE_CASHFREE_REQUEST,
    UPDATE_CASHFREE_SUCCESS,
    GET_CASHFREE_REQUEST,
    GET_CASHFREE_SUCCESS,
    SEARCH_CASHFREE_REQUEST,
    SEARCH_CASHFREE_SUCCESS,
    LIST_CASHFREE_REQUEST,
    LIST_CASHFREE_SUCCESS,
    REMOVE_CASHFREE_REQUEST,
    REMOVE_CASHFREE_SUCCESS
  } from '../actionTypes';
  
  const cashfreeReducer = (state = {}, action) => {
    switch (action.type) {  
      case INIT_CASHFREE_REQUEST:
        state = { ...state, insert: {}, update: {}, get: {} , search: {} , user: {}, list: {}, remove: {} };
      break;
      case INSERT_CASHFREE_REQUEST:
        state = { ...state, insert: {} };
        break;
        case UPDATE_CASHFREE_REQUEST:
          state = { ...state, update: {} };
          break;
      case GET_CASHFREE_REQUEST:
          state = { ...state, get: [] };
          break;
      case SEARCH_CASHFREE_REQUEST:
        state = { ...state, search: [] };
        break;
      case LIST_CASHFREE_REQUEST:
          state = { ...state, list: {} };
          break;
      case REMOVE_CASHFREE_REQUEST:
        state = { ...state, remove: {} };
        break;
    case INSERT_CASHFREE_SUCCESS:
        state = { ...state,
                  insert:{
                      ...action.payload  
                  }
              };
        break;  
    case UPDATE_CASHFREE_SUCCESS:
          state = { ...state,
                    update:{
                        ...action.payload  
                    }
                };
          break;  
    
        case GET_CASHFREE_SUCCESS:
        state = { ...state,
                  get:{
                      ...action.payload  
                  }
              };
        break;
    case SEARCH_CASHFREE_SUCCESS:
      state = { ...state,
                search:{
                    ...action.payload  
                }
            };
      break;
      case LIST_CASHFREE_SUCCESS:
        state = { ...state,
                  list:{
                      ...action.payload
                  }
              };
      break;        
      case REMOVE_CASHFREE_SUCCESS:
        state = { ...state,
                  remove:{
                      ...action.payload
                  }
                };        
        break;
      default:
        break;
    }
    return state;
  };
  export default cashfreeReducer;