export const INIT_COMPANIES_REQUEST = 'INIT_COMPANIES_REQUEST';
export const INSERT_COMPANIES_REQUEST = 'INSERT_COMPANIES_REQUEST';
export const UPDATE_COMPANIES_REQUEST = 'UPDATE_COMPANIES_REQUEST';
export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
export const LIST_COMPANIES_REQUEST = 'LIST_COMPANIES_REQUEST';
export const USER_COMPANIES_REQUEST = 'USER_COMPANIES_REQUEST';
export const REMOVE_COMPANIES_REQUEST = 'REMOVE_COMPANIES_REQUEST';
export const INTERESTED_COMPANIES_REQUEST = 'INTERESTED_COMPANIES_REQUEST';

export const INSERT_COMPANIES_SUCCESS = 'INSERT_COMPANIES_SUCCESS';
export const UPDATE_COMPANIES_SUCCESS = 'UPDATE_COMPANIES_SUCCESS';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const LIST_COMPANIES_SUCCESS = 'LIST_COMPANIES_SUCCESS';
export const USER_COMPANIES_SUCCESS = 'USER_COMPANIES_SUCCESS';
export const REMOVE_COMPANIES_SUCCESS = 'REMOVE_COMPANIES_SUCCESS';
export const INTERESTED_COMPANIES_SUCCESS = 'INTERESTED_COMPANIES_SUCCESS';
