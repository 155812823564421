import {call, put, takeLatest} from 'redux-saga/effects';
import {
    INSERT_PLANS_REQUEST,
    INSERT_PLANS_SUCCESS,
    SEARCH_PLANS_REQUEST,
    SEARCH_PLANS_SUCCESS,
    UPDATE_PLANS_REQUEST,
    UPDATE_PLANS_SUCCESS,
    GET_PLANS_REQUEST,
    GET_PLANS_SUCCESS,
    LIST_PLANS_REQUEST,
    LIST_PLANS_SUCCESS,
    BRANCH_PLANS_REQUEST,
    BRANCH_PLANS_SUCCESS,
    REMOVE_PLANS_REQUEST,
    REMOVE_PLANS_SUCCESS
} from '../../actionTypes/plans';

import { doInsertPlans, doGetPlans, doSearchPlans,
    doListPlans, doRemovePlans, doUpdatePlans,
    doBranchPlans } from '../../../com/plans';

    import { doPlansSchedule } from '../../../com/schedule';
    
import { doGetBranch } from '../../../com/branch';
    
import { doGetCategories } from '../../../com/categories';

function* insertPlansFetch(params) {
    const json = yield call(doInsertPlans, params.payload);   
    yield put({ type: INSERT_PLANS_SUCCESS, payload: json.data});
}

function* searchPlansFetch(params) {
    const json = yield call(doSearchPlans, params.payload);
    let { plans } = json.data;
    for (let index = 0; index < plans.length; index++) {
        const branchResponse =  yield call(doGetBranch, {
            branch_id: json.data && plans[index].branch_id
        });
        
        plans[index].branch = branchResponse.data.branch; 
        
        const categoriesResponse =  yield call(doGetCategories, {
            categories_id: json.data && plans[index].categories_id
        });

        plans[index].categories = categoriesResponse.data.categories;

        const scheduleResponse =  yield call(doPlansSchedule, {
            plans_id: json.data && plans[index].plans_id
        });
    
        plans[index].schedules = scheduleResponse.data.schedules; 
    }
    yield put({ type: SEARCH_PLANS_SUCCESS, payload: json.data});
}

function* updatePlansFetch(params) {
    const json = yield call(doUpdatePlans, params.payload);   
    yield put({ type: UPDATE_PLANS_SUCCESS, payload: json.data});
}

function* getPlansFetch(params) {
    const json = yield call(doGetPlans, params.payload);   
    const branchResponse =  yield call(doGetBranch, {
        branch_id: json.data && json.data.plans.branch_id
    });
    json.data.plans.branch = branchResponse.data.branch; 
    
    const categoriesResponse =  yield call(doGetCategories, {
        categories_id: json.data && json.data.plans.categories_id
    });
    
    json.data.plans.categories = categoriesResponse.data.categories;

    const scheduleResponse =  yield call(doPlansSchedule, {
        plans_id: json.data && json.data.plans.plans_id
    });

    json.data.plans.schedules = scheduleResponse.data.schedules; 
    yield put({ type: GET_PLANS_SUCCESS, payload: json.data});
}

function* listPlansFetch(params) {
    const json = yield call(doListPlans, params.payload);
    yield put({ type: LIST_PLANS_SUCCESS, payload: json.data});
}

function*  branchPlansFetch(params) {
    const json = yield call(doBranchPlans, params.payload);   
    yield put({ type: BRANCH_PLANS_SUCCESS, payload: json.data});
}

function* removePlansFetch(params) {
    const json = yield call(doRemovePlans, params.payload);   
    yield put({ type: REMOVE_PLANS_SUCCESS, payload: json.data});
}

export function* insertPlansSaga() {
    yield takeLatest(INSERT_PLANS_REQUEST, insertPlansFetch)
}

export function* updatePlansSaga() {
    yield takeLatest(UPDATE_PLANS_REQUEST, updatePlansFetch)
}

export function* removePlansSaga() {
    yield takeLatest(REMOVE_PLANS_REQUEST, removePlansFetch)
}

export function* getPlansSaga() {
    yield takeLatest(GET_PLANS_REQUEST, getPlansFetch)
}

export function* searchPlansSaga() {
    yield takeLatest(SEARCH_PLANS_REQUEST, searchPlansFetch)
}

export function* branchPlansSaga() {
    yield takeLatest(BRANCH_PLANS_REQUEST, branchPlansFetch)
}

export function* listPlansSaga() {
    yield takeLatest(LIST_PLANS_REQUEST, listPlansFetch)
}