import { 
    INSERT_CASHFREE_REQUEST,
    UPDATE_CASHFREE_REQUEST,
    GET_CASHFREE_REQUEST,
    SEARCH_CASHFREE_REQUEST,
    LIST_CASHFREE_REQUEST,
    REMOVE_CASHFREE_REQUEST,
    INIT_CASHFREE_REQUEST
} from '../actionTypes';

export const initCashfreeRequest = (params) => {
  return {
    type: INIT_CASHFREE_REQUEST,
    payload:params
  };
};

export const updateCashfreeRequest = (params) => {
  return {
    type: UPDATE_CASHFREE_REQUEST,
    payload:params
  };
};

export const insertCashfreeRequest = (params) => {
  return {
    type: INSERT_CASHFREE_REQUEST,
    payload:params
  };
};

export const getCashfreeRequest = (params) => {
  return {
    type: GET_CASHFREE_REQUEST,
    payload:params
  };
};

export const searchCashfreeRequest = (params) => {
  return {
    type: SEARCH_CASHFREE_REQUEST,
    payload:params
  };
};

export const listCashfreeRequest = (params) => {
  return {
    type: LIST_CASHFREE_REQUEST,
    payload:params
  };
};

export const removeCashfreeRequest = (params) => {
  return {
    type: REMOVE_CASHFREE_REQUEST,
    payload:params
  };
};