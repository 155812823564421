import {call, put, takeLatest} from 'redux-saga/effects';
import {
    INSERT_SCHEDULE_REQUEST,
    INSERT_SCHEDULE_SUCCESS,
    UPDATE_SCHEDULE_REQUEST,
    UPDATE_SCHEDULE_SUCCESS,
    GET_SCHEDULE_REQUEST,
    GET_SCHEDULE_SUCCESS,
    LIST_SCHEDULE_REQUEST,
    LIST_SCHEDULE_SUCCESS,
    PLANS_SCHEDULE_REQUEST,
    PLANS_SCHEDULE_SUCCESS,
    REMOVE_SCHEDULE_REQUEST,
    REMOVE_SCHEDULE_SUCCESS
} from '../../actionTypes/schedule';

import { doInsertSchedule, doGetSchedule,
    doListSchedule, doRemoveSchedule, doUpdateSchedule,
    doPlansSchedule } from '../../../com/schedule';

import { doGetPlans } from '../../../com/plans';

function* insertScheduleFetch(params) {
    const json = yield call(doInsertSchedule, params.payload);   
    yield put({ type: INSERT_SCHEDULE_SUCCESS, payload: json.data});
}


function* updateScheduleFetch(params) {
    const json = yield call(doUpdateSchedule, params.payload);   
    yield put({ type: UPDATE_SCHEDULE_SUCCESS, payload: json.data});
}

function* getScheduleFetch(params) {
    const json = yield call(doGetSchedule, params.payload);  
    const plansResponse =  yield call(doGetPlans, {
        plans_id: json.data && json.data.schedule.plans_id
    });
    json.data.schedule.plans = plansResponse.data.plans; 
    yield put({ type: GET_SCHEDULE_SUCCESS, payload: json.data});
}

function* listScheduleFetch(params) {
    const json = yield call(doListSchedule, params.payload);
    let data = [];
    const {scheduleList} = json.data;
    let plans = {};
    for(let index = 0; index < scheduleList.length; index++) {
        const plans_id = scheduleList[index].plans_id;
        if (plans[plans_id]) {
            scheduleList[index].plans = plans[plans_id];     
        } else {
            const response = yield call(doGetPlans, {plans_id});
            scheduleList[index].plans = response.data.plans; 
            plans[plans_id] = scheduleList[index].plans;
        }
    }
    yield put({ type: LIST_SCHEDULE_SUCCESS, payload: json.data});
}

function*  plansScheduleFetch(params) {
    const json = yield call(doPlansSchedule, params.payload);   
    yield put({ type: PLANS_SCHEDULE_SUCCESS, payload: json.data});
}

function* removeScheduleFetch(params) {
    const json = yield call(doRemoveSchedule, params.payload);   
    yield put({ type: REMOVE_SCHEDULE_SUCCESS, payload: json.data});
}

export function* insertScheduleSaga() {
    yield takeLatest(INSERT_SCHEDULE_REQUEST, insertScheduleFetch)
}

export function* updateScheduleSaga() {
    yield takeLatest(UPDATE_SCHEDULE_REQUEST, updateScheduleFetch)
}

export function* removeScheduleSaga() {
    yield takeLatest(REMOVE_SCHEDULE_REQUEST, removeScheduleFetch)
}

export function* getScheduleSaga() {
    yield takeLatest(GET_SCHEDULE_REQUEST, getScheduleFetch)
}

export function* plansScheduleSaga() {
    yield takeLatest(PLANS_SCHEDULE_REQUEST, plansScheduleFetch)
}

export function* listScheduleSaga() {
    yield takeLatest(LIST_SCHEDULE_REQUEST, listScheduleFetch)
}