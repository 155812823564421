import {call, put, takeLatest} from 'redux-saga/effects';
import {
    BUSINESS_EARNINGS_REQUEST,
    BUSINESS_EARNINGS_SUCCESS,
    BUSINESS_EARNINGS_HISTORY_REQUEST,
    BUSINESS_EARNINGS_HISTORY_SUCCESS
} from '../../actionTypes/earnings';

import { doBusinessEarnings, doBusinessEarningsHistory  } from '../../../com/earnings';
import { getUser } from '../../../com/user'
function*  businessEarningsFetch(params) {
    const json = yield call(doBusinessEarnings, params.payload);   
    yield put({ type: BUSINESS_EARNINGS_SUCCESS, payload: json.data});
}

function*  businessEarningsHistoryFetch(params) {
    const json = yield call(doBusinessEarningsHistory, params.payload);   
    const { history } = json.data
    for (let index = 0; index < history.length; index++) {
        const consumerResponse =  yield call(getUser, {
            remote_user_id: json.data && history[index].consumer_id
        });
        history[index].consumer = consumerResponse.data.user; 
    }
    yield put({ type: BUSINESS_EARNINGS_HISTORY_SUCCESS, payload: json.data});
}

export function* businessEarningsSaga() {
    yield takeLatest(BUSINESS_EARNINGS_REQUEST, businessEarningsFetch)
}

export function* businessEarningsHistorySaga() {
    yield takeLatest(BUSINESS_EARNINGS_HISTORY_REQUEST, businessEarningsHistoryFetch)
}