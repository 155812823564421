import React, {useState} from 'react';
import './index.scss';
import showSvg from '../../asset/images/ew-login-layout.svg';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import LTransaction from '../../components/Orders/List';
const TransactionLayout = (props) => {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [transaction_id, setTransactionId] = useState();
    return (
    <>    
    <Container fluid className={'ew-transaction'}>
        <Row className={'justify-content-center vh-100 pt-4'}>
            {index === 0 && <Col md={10}><LTransaction {...props} setIndex={setIndex} setTransactionId={setTransactionId} /></Col>}
        </Row>
    </Container>
    </>
    );
}

export default TransactionLayout;