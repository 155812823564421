import React, {useState, useEffect} from 'react';
import {Row, InputGroup, Form, Button, Card, Table, Image, Col} from 'react-bootstrap';
import { useNavigate} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {isUndefined } from 'lodash';
import {FiEdit, FiTrash} from 'react-icons/fi';
import Confirmation from '../../../../components/Confirmation';
import PageHeader from '../../../../components/PageHeader';
import Error from '../../../../components/Error';
import { listCategoriesRequest, removeCategoriesRequest } from '../../action';
const ListCategories = (props) => {
    const {CategoriesList, CategoriesDelete, setIndex, setCategoriesId} = props;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
        content: '',
        variant: ''
    });
    useEffect(() => {
        setLoading(true);
        props.listCategoriesRequest({});
    }, []);

    useEffect(() => {
        const {success, code, message} = CategoriesList;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [CategoriesList]);

    useEffect(() => {
        const {success, code, message} = CategoriesDelete;
        if(isUndefined(success)) {
            return false;
        }
        if(success === true) { 
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'success'
            });
            setShowError(true);
            setLoading(false);
            props.listCategoriesRequest({});
        } else {
            setInfo({
                ...info,
                    content: `${code} - ${message}`,
                    variant: 'warning'
            });
            setShowError(true);
            setLoading(false);
        } 
    }, [CategoriesDelete]);

    if (CategoriesList.categories && CategoriesList.categories.length === 0) {
        return (<Confirmation title={'My Categories'} 
                type={'ListCategories'}  
                setIndex={setIndex} />);
    }

    return (
        <React.Fragment>
        <PageHeader title={'My Categories'} show={loading} className={'mb-4'}/>
        {showError && <Error content={info.content} variant={info.variant} setShowError={setShowError} />}
        <Card className={'border-0'}>
            <Card.Header className={'p-2 bg-white text-end'}>
                <Row className={'justify-content-end'}>
                    <Col md={3}>
                        <Button className={'btn btn-sm btn-primary text-right'} 
                        onClick={() => setIndex(1)}><small>Add Categories</small></Button>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
        <Row>
            <Table responsive="md">
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Active</th>
                        <th>Icon</th>
                        <th>About</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {CategoriesList.categories && CategoriesList.categories.map((categories, key) => {
                    return (
                        <tr key={key}>
                            <td><Image src={'https://via.placeholder.com/20'} roundedCircle/></td>
                            <td>{categories.name}</td>
                            <td>{categories.is_active.toString()}</td>
                            <td>{categories.icon}</td>
                            <td>{categories.about}</td>
                            <td onClick={() => {setIndex(1);setCategoriesId(categories.categories_id)}}><FiEdit /></td>
                            <td onClick={() => {props.removeCategoriesRequest({categories_id: categories.categories_id})}}><FiTrash /></td>
                        </tr>
                    )
                    })}    
                    </tbody>
            </Table>
        </Row>
        </Card.Body></Card>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
      CategoriesList: state.categories.list || {},
      CategoriesDelete: state.categories.remove || {}
    }
};
  
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        listCategoriesRequest,
        removeCategoriesRequest
    }, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(ListCategories);