import React, {useState, Suspense} from 'react';
import './index.scss';
import showSvg from '../../asset/images/ew-login-layout.svg';
import {Container, Row, Col, Image, Spinner} from 'react-bootstrap';
import LPurchase from '../../components/Purchase/List';
import EPurchase from '../../components/Purchase/Edit';

const Development = React.lazy(() => import('../../components/Purchase/Prepare/Development'));
const Production = React.lazy(() => import('../../components/Purchase/Prepare/Production'));

const PurchaseLayout = (props) => {
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [purchase_id, setPurchaseId] = useState();
    return (
    <>    
    <Suspense fallback={<div>Loading..</div>}>
        { process.env.REACT_APP_ENVIRONMENT === 'development' && <Development /> }
        { process.env.REACT_APP_ENVIRONMENT === 'production' && <Development /> }
    </Suspense>
    <Container fluid className={'ew-purchase'}>
        <Row className={'justify-content-center vh-100 pt-4'}>
            {index === 0 && <Col md={10}><LPurchase {...props} setIndex={setIndex} setPurchaseId={setPurchaseId} /></Col>}
            {index === 1 && <Col md={12}><EPurchase {...props} setIndex={setIndex} /></Col>}
        </Row>
    </Container>
    </>
    );
}

export default PurchaseLayout;